import FixedHeightPage from '../templates/FixedHeightPage';
import SignUpForm from '../../../components/UI/Forms/AuthForms/SignUpForm';
import Dialogue from '../../../components/Dialogue';
import styled from 'styled-components';

const StyledFooter = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: blue;
`;

const SignUpPage = () => {
  return (
    <FixedHeightPage title={''}>
      <Dialogue title={'Sign Up'}>
        <SignUpForm />
        <StyledFooter></StyledFooter>
      </Dialogue>
    </FixedHeightPage>
  );
};

export default SignUpPage;
