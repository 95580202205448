import { useState, useRef } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SiteHeader from './SiteHeader/SiteHeader';
import BurgerMenu from './BurgerMenu';
import ContentItems from './SiteHeader/ContentItems';
import NavigationItems from './SiteHeader/NavigationItems/NavigationItems';

import './Site.css';

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const StyledScroller = styled.div<{ noContainerScroll: boolean }>`
  margin-top: 56px;
  height: calc(100% - 56px);
  overflow-y: ${(props) => (props.noContainerScroll ? null : 'scroll')};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
`;

const Site = () => {
  const [isInBurgerMode, setIsInBurgerMode] = useState(false);

  const navigate = useNavigate();

  const isMatch = useMatch('/login');
  console.log(isMatch);

  // const { pathname } = props.location;
  // const { url } = props.match;
  const noContainerScroll = false;
  // pathname === `/login` || pathname === `/signup` || pathname === '/reset' || pathname === '/betasignup' ? true : false;

  const scrollerRef = useRef<HTMLDivElement>(null);

  function onHeaderItemClicked(pathname: string) {
    navigate(pathname);
  }

  function onHeaderItemClicked2(pathname: string) {
    navigate(pathname);
    toggleBurger();
  }

  function toggleBurger() {
    setIsInBurgerMode(!isInBurgerMode);
  }

  function scrollTo({ pos, noScroll }: { pos: number; noScroll?: true }) {
    if (scrollerRef.current) {
      if (noScroll) {
        scrollerRef.current.scrollTop = pos;
      } else {
        scrollerRef.current.scrollTo({ behavior: 'smooth', top: pos });
      }
    }
  }

  if (isInBurgerMode) {
    return (
      <StyledLayout>
        <SiteHeader onHeaderItemClicked={onHeaderItemClicked} toggleBurger={toggleBurger} isOpenBurger={isInBurgerMode} />
        <StyledScroller id="bob" ref={scrollerRef} noContainerScroll={noContainerScroll}>
          <BurgerMenu toggleBurger={toggleBurger}>
            <ContentItems onHeaderItemClicked={onHeaderItemClicked2} />
            <NavigationItems insideBurger={true} toggleBurger={toggleBurger} />
          </BurgerMenu>
        </StyledScroller>
      </StyledLayout>
    );
  }

  return (
    <StyledLayout>
      <SiteHeader onHeaderItemClicked={onHeaderItemClicked} toggleBurger={toggleBurger} isOpenBurger={isInBurgerMode} />
      <StyledScroller id="bob" ref={scrollerRef} noContainerScroll={noContainerScroll}>
        <Outlet />
      </StyledScroller>
    </StyledLayout>
  );
};

export default Site;
