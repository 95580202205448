import React, { useState, useEffect } from 'react';
import ContentService from '../../../services/ContentService';
import { ArticleType } from '../../../types';
import { serialize } from '../../../slate/serializer';
import LegalPage from '../templates/LegalPage';

const PrivacyPage = () => {
  const [article, setArticle] = useState<null | ArticleType>(null);

  useEffect(() => {
    getArticle();
    async function getArticle() {
      const response = await ContentService.getContent('privacy');
      const data = (await response.json()) as ArticleType;
      setArticle(data);
    }
  }, []);

  return !article ? null : (
    // <div style={{ display: 'flex' }}>
    //   <LegalNav />
    //   <SimplePage title={article.title}>{article.slate.map((node) => serialize(node))}</SimplePage>
    // </div>
    <LegalPage title={article.title}>
      {article.slate.map((node, i) => serialize(node, i))}
    </LegalPage>
  );
};

export default PrivacyPage;
