import { useContext, useEffect } from 'react';
import Site from './containers/Site/Site';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { getTokenData } from './helpers/jwtHelpers';
import useAuth from './helpers/useAuth';
import FeaturesPage from './containers/Site/Pages/Features';
import LearnPage from './containers/Site/Pages/LearnPage';
import Login from './containers/Site/Pages/Login';
import SignUp from './containers/Site/Pages/SignUp';
import ResetPassword from './containers/Site/Pages/ResetPassword';
import NewPasswordPage from './containers/Site/Pages/NewPassword';
import Oops from './containers/Site/Pages/Oops';
import Terms from './containers/Site/Pages/Terms';
import Beta from './containers/Site/Pages/Beta';
import Privacy from './containers/Site/Pages/Privacy';
import CookiePolicy from './containers/Site/Pages/CookiePolicy';
import PricingPage from './containers/Site/Pages/Pricing/PricingPage';
import ReleasesPage from './containers/Site/Pages/ReleasesPage';
import SignUpForBetaPage from './containers/Site/Pages/SignUpForBeta';
import SubscribeSuccess from './containers/Site/Pages/SubscribeSuccess';
import SubscribeCancel from './containers/Site/Pages/SubscribeCancel';
import Landing from './containers/Site/Landing/Landing';
import AuthenticationService from './services/AuthenticationService';
import AuthContextProvider, { AuthContext } from './AuthContext';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Site />,
      children: [
        {
          path: '/',
          element: <Landing />,
        },
        {
          path: 'features',
          element: <FeaturesPage scrollTo={(p) => {}} />,
        },
        {
          path: 'learn',
          element: <LearnPage scrollTo={(p) => {}} />,
        },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'signup',
          element: <SignUp />,
        },
        {
          path: 'reset',
          element: <ResetPassword />,
        },
        {
          path: 'newpassword',
          element: <NewPasswordPage />,
        },
        {
          path: 'oops',
          element: <Oops />,
        },
        {
          path: 'terms',
          element: <Terms />,
        },
        {
          path: 'beta',
          element: <Beta />,
        },
        {
          path: 'privacy',
          element: <Privacy />,
        },
        {
          path: 'cookiepolicy',
          element: <CookiePolicy />,
        },
        {
          path: 'pricing',
          element: <PricingPage />,
        },
        {
          path: 'releases',
          element: <ReleasesPage />,
        },
        {
          path: 'betasignup',
          element: <SignUpForBetaPage />,
        },
        {
          path: 'subscribeSuccess',
          element: <SubscribeSuccess />,
        },
        {
          path: 'subscribeCancel',
          element: <SubscribeCancel />,
        },
      ],
    },
  ],
  {}
);

const RootApp = () => {
  const auth = useAuth();
  const b = useContext(AuthContext);

  useEffect(() => {
    const tokenData = getTokenData();
    if (tokenData && !tokenData.isExpired) {
      AuthenticationService.stepin()
        .then((data) => {
          console.log('in root', data.data);
          b.setAuthData(data.data);
        })
        .catch((error) => {
          console.log('Manual signin required.');
        });
    }
  }, []);

  return <RouterProvider router={router} />;
};

export default RootApp;
