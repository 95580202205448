import { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { getTokenData } from './jwtHelpers';

const useUser = () => {
  const { auth: authState } = useContext(AuthContext);
  const tokenData = getTokenData();

  if (!authState?.auth || !authState.user || !tokenData?.userId) {
    return { isLoggedIn: false, name: undefined, email: undefined, userId: undefined, subscriptionStatus: undefined };
  } else {
    const { user, auth } = authState;
    const name = (user.firstName && user.lastName && `${user.firstName} ${user.lastName}`) || user.firstName || user.lastName || auth.email;
    return { isLoggedIn: true, name, email: auth.email, userId: tokenData.userId, subscriptionStatus: auth.subscriptionStatus };
  }
};

export default useUser;
