import SimplePage from '../templates/SimplePage';
import Dialogue from '../../../components/Dialogue';

const Oops = () => {
  return (
    <SimplePage title={'Give your new password here'}>
      <Dialogue title={'new Password'}>
        <div>An error occured.</div>
      </Dialogue>
    </SimplePage>
  );
};

export default Oops;
