// import Button from '../../../components/UI/Button/Button'

interface Props {
  onSubscribe: Function;
}

const Subscribe = () => {
  // function goSubscribe () {
  //   const payload = {
  //     subscriptionType: 'monthly'
  //   }
  //   props.onSubscribe(payload)
  // }

  return <div>Subscription process cancelled</div>;
};

export default Subscribe;
