import styled from 'styled-components';
import { StyledRow, StyledWidthContainer } from './Row';
import { media } from '../../../styles';
import { useNavigate } from 'react-router-dom';

const StyledCTAColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;

  ${media.forPhoneOnly`
    width: auto;
    padding: 0 20px;
  `}
`;

const StyledText = styled.div`
  padding: 50px 20px;
  font-size: 24px;

  ${media.forTabletLandscapeUp`
      padding: 100px 20px;
      font-size: 36px;
    `}
`;

const StyledBGContainer = styled(StyledRow)``;

const StyledColumnWidthContainer = styled(StyledWidthContainer)`
  ${media.forTabletLandscapeUp`
  display: flex;
  flex-direction: column;
  `}
`;

const CTAMain = styled.div`
  font-size: 30px;
  color: white;

  ${media.forTabletLandscapeUp`
    font-size: 48px;
  `}
`;

const CTASub = styled.div`
  display: flex;
  font-size: 18px;
  color: white;
  margin: 5px 0;
  align-items: center;
  cursor: pointer;

  ${media.forTabletLandscapeUp`
    font-size: 26px;
  `}
`;

interface Props {}

const CallToAction = (props: Props) => {
  const navigate = useNavigate();

  return (
    <StyledBGContainer backgroundColor={'light'}>
      <StyledColumnWidthContainer>
        <StyledText>Seize the moment. Craft your story.</StyledText>
        <div className={'site-cta'}>
          <StyledCTAColumn>
            <CTASub></CTASub>
            <CTAMain>Beta opening soon.</CTAMain>
          </StyledCTAColumn>
          <div className={'site-cta-action'} onClick={() => navigate('/betasignup')}>
            Sign up for Beta
          </div>
        </div>
      </StyledColumnWidthContainer>
    </StyledBGContainer>
  );
};

export default CallToAction;
