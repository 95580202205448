import Icon, { IconSize } from '../../../icons/Icon';
import './Button.css';
import { IconName } from '../../../icons/IconSVG';
import { CSSProperties } from 'react';
interface Props {
  buttonType?: 'standard' | 'site' | 'action';
  title?: string;
  type?: 'button' | 'submit' | 'reset';
  children?: string;
  disabled?: boolean;
  clicked?: (e: React.MouseEvent) => void;
  style?: CSSProperties;
  iconName?: IconName;
  className?: string;
}

const Button = ({ type, title, iconName, children, disabled, clicked, style, buttonType = 'standard', className }: Props) => {
  return (
    <button
      style={style}
      className={`szen-button szen-button-${buttonType} ${disabled ? 'szen-button-disabled' : ''} ${className}`}
      type={type}
      disabled={disabled}
      onClick={clicked}
    >
      {iconName && <Icon size={IconSize.small} iconName={iconName} className="szen-m-right-s" />}
      {title || children}
    </button>
  );
};

export default Button;
