import styled from 'styled-components';
import Row, { StyledRow } from './Row';
import { landingData, isLandingDataItemComponent } from './Landingdata';
import CallToAction from './CallToAction';
import Display from './Display';
import { media } from '../../../styles';
import Footer from '../Footer/Footer';
import ExperimentalRow from './experimental/ExperimentalRow';

const StyledTopRow = styled.div`
  width: 100%;
  min-height: calc(100vh - 56px);
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  /* background-image: url('img/landingLARGE.svg'); */
  /* background-size: contain; */
  /* background-color: transparent; */
  /* background-size: cover; */
  /* background-position: center center; */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */

  ${media.forPhoneOnly`
    align-items: center;
    /* background-image: url('img/langingMOBILE.svg'); */
  `}
`;
const StyledWidthContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
`;

const RowContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const TitleHolder = styled.div`
  ${media.forPhoneOnly`
    display: none;
  `}

  transition: 2s all;
`;

// const StyledSiteTitle = styled.div`
//   margin: 120px 0 10px 0;
//   font-size: 3rem;
//   color: black;
// `;
// const StyledSiteSubtitle = styled.div`
//   font-size: 1.5rem;
//   margin: 10px 0 100px 0;
// `;

// const ImageHolder = styled.div`
//   /* flex: 1 1 auto; */
//   width: 100%;
//   /* max-height: 600px; */
//   /* border: 1px solid red; */
// `;

export const StyledIntroRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 200px;
  width: 100%;

  ${media.forTabletLandscapeUp`
    /* min-height: 640px; */
  `}

  ${media.forDesktopUp`
    /* min-height: 680px; */
  `}
`;

const StyledBannerImage = styled.img`
  width: 90%;
  z-index: 0;
  align-self: center;
  height: auto;

  ${media.forDesktopUp`
    width: 60%;
  `}
`;

const StyledIntroImage = styled.img`
  width: 100%;
  z-index: 0;
  align-self: center;
  height: auto;
  /* margin-bottom: 300px; */

  /* ${media.forDesktopUp`
    width: 60%;
  `} */
`;

const StyledBottomText = styled.div`
  font-family: Soleil;
  /* text-transform: uppercase; */
  font-size: 16px;
  align-self: center;
  justify-self: flex-end;
  margin-top: 100px;
  /* position: absolute;
  bottom: 10px;
  vertical-align: center; */

  ${media.forTabletLandscapeUp`
    font-size: 24px;
  `}
`;

const StyledIntroHeader = styled.div`
  font-family: Soleil;
  font-size: 28px;
  margin: 0 48px 48px 48px;
  max-width: 800px;
  font-weight: 700;
  text-align: center;

  ${media.forTabletLandscapeUp`
    font-size: 40px;
  `}
`;

const StyledIntro = styled.div`
  font-family: Soleil;
  font-size: 18px;
  margin: 0 50px 80px 50px;
  max-width: 1000px;

  ${media.forTabletLandscapeUp`
    margin-top: 32px;
    font-size: 30px;
    letter-spacing: 0.1px;
    line-height: 36px;
  `}
`;

const Landing = () => {
  const experimental = false;

  return (
    <>
      <StyledTopRow>
        <RowContents>
          <TitleHolder>
            {/* <StyledSiteTitle>STORYZEN</StyledSiteTitle> */}
            {/* <StyledSiteSubtitle>For Stories That Happen in Time and Place</StyledSiteSubtitle> */}
          </TitleHolder>
          <StyledBannerImage
            srcSet="img/landingMOBILE.svg 600w "
            sizes="(max-width: 1800px) 600px, 1200px"
            src="img/landingMOBILE.svg"
            alt="Storyvera banner"
          />
        </RowContents>
        <StyledBottomText>Crafting Stories</StyledBottomText>
      </StyledTopRow>
      <StyledIntroRow style={{ marginTop: '0px' }}>
        <StyledWidthContainer>
          <RowContents>
            <StyledIntroHeader>Transform ideas to stories</StyledIntroHeader>
            <StyledIntroImage src="img/fooooo.svg" alt="Storyvera banner" />
            <StyledIntro>
              Storyvera is an online application for planning and writing stories. Storyvera allows both creative fiction and creative
              non-fiction alike, it writers to grow their ideas into completion and transform them into a story.
            </StyledIntro>
          </RowContents>
        </StyledWidthContainer>
      </StyledIntroRow>
      {/* <StyledIntroRow>
        <StyledWidthContainer>
          <RowContents>
            <StyledIntroImage
              // srcSet="img/landingMOBILE.svg 600w "
              // sizes="(max-width: 1800px) 600px, 1200px"
              src="img/writer.png"
              alt="Storyvera banner"
            />
          </RowContents>
        </StyledWidthContainer>
      </StyledIntroRow> */}
      {/* <StyledIntroRow className={'wide'}>
        <StyledWidthContainer>
          <StyledIntroImage
            // srcSet="img/landingMOBILE.svg 600w "
            // sizes="(max-width: 1800px) 600px, 1200px"
            src="img/boo.svg"
            // alt="Storyvera banner"
          />
        </StyledWidthContainer>
      </StyledIntroRow> */}

      {Object.values(landingData).map((dataItem, index) => {
        if (isLandingDataItemComponent(dataItem)) {
          return (
            <StyledRow backgroundColor={dataItem.backgroundColor}>
              <StyledWidthContainer>
                <div>{dataItem.title}</div>
                <Display images={dataItem.componentId} />
              </StyledWidthContainer>
            </StyledRow>
          );
        }

        if (experimental) {
          return <ExperimentalRow key={dataItem.title} data={dataItem} index={index} />;
        }

        return <Row key={dataItem.title} data={dataItem} index={index} />;
      })}
      <StyledWidthContainer>
        <CallToAction />
      </StyledWidthContainer>

      <Footer />
    </>
  );
};

export default Landing;
