import styled from 'styled-components';
import AccountMenu from '../../../../components/hovers/AccountMenu';
import { media } from '../../../../styles';
import useAuth from '../../../../helpers/useAuth';
import { useNavigate } from 'react-router-dom';
import { navigateToApp } from '../../../../helpers/navigationHelpers';
import useUser from '../../../../helpers/useUser';

const StyledNavigationItems = styled.ul`
  margin: 0;
  padding: 5px 15px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  flex-flow: row;
  align-items: center;

  ${media.forPhoneOnly`
    flex-direction: column;
  `}
`;

export const StyledNavigationItem = styled.li`
  margin: 0;
  display: flex;
  align-items: center;
  width: auto;
  cursor: pointer;

  font-family: Lato;
  color: blue;
  text-decoration: none;
  padding: 0px 10px;
  display: block;

  &:hover {
    color: gray;
  }

  ${media.forPhoneOnly`
    display: flex;
    justify-content: center;
    /* font-family: 'Open Sans'; */
    color: white;
    background: var(--site-button-background);
    font-weight: normal;
    width: 200px;
    margin: 10px 0px;
    padding: 5px;
    border-radius: 5px;
  `}
`;

const StyledLogout = styled.span`
  font-family: Lato;
  color: blue;
  font-weight: bold;
  text-decoration: none;
  padding: 0px 10px;
  box-sizing: border-box;
  display: block;

  &:hover {
    color: gray;
  }

  ${media.forPhoneOnly`
    color: white;
  `}
`;

const NavigationItems = ({ toggleBurger, insideBurger }: { toggleBurger?: Function; insideBurger: boolean }) => {
  const { logoutUser } = useAuth();
  const { isLoggedIn } = useUser();
  const navigate = useNavigate();
  const openDash = (_event: React.SyntheticEvent) => {
    navigateToApp(navigate);
  };

  function logout() {
    logoutUser();
  }

  function navigateTo(pathname: string) {
    navigate(pathname);
    if (toggleBurger) toggleBurger();
  }

  if (isLoggedIn) {
    return (
      <StyledNavigationItems>
        <StyledNavigationItem>
          <StyledLogout onClick={openDash}>Dashboard</StyledLogout>
        </StyledNavigationItem>
        {insideBurger ? (
          <StyledNavigationItem>
            <StyledLogout onClick={logout}>Logout</StyledLogout>
          </StyledNavigationItem>
        ) : (
          <AccountMenu />
        )}
      </StyledNavigationItems>
    );
  }

  return (
    <StyledNavigationItems>
      <StyledNavigationItem onClick={() => navigateTo('/login')}>Sign In</StyledNavigationItem>
      <StyledNavigationItem onClick={() => navigateTo('/signup')}>Start Free Trial</StyledNavigationItem>
    </StyledNavigationItems>
  );
};

export default NavigationItems;
