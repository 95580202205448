import axios from 'axios';

import { isTokenExpired } from '../helpers/jwtHelpers';
import { OutgoingHttpHeaders } from 'http';

function isAuthenticated() {
  // Checks if there is a saved token and it's still valid
  const token = localStorage.getItem('token');
  if (token) {
    return !isTokenExpired(token);
  } else {
    return false;
  }
}

export function getToken() {
  const token = localStorage.getItem('token');
  return token ? token : 'undefined';
}

const api = () => {
  const headers: OutgoingHttpHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (isAuthenticated()) {
    headers['Authorization'] = getToken();
  }

  return axios.create({
    baseURL: process.env.REACT_APP_baseURL,
    headers: headers,
    withCredentials: true, // process.env.NODE_ENV === "development" ? true : false
  });
};

export default api;
