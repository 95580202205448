const LoggerIssue = {
  GENERAL: 'general',
  SCRIPTUM_LOAD: 'scriptum_load',
  TEST: 'test',
  ELEMENT_NOT_FOUND: 'element_not_found',
};

interface ReportPayload {
  date: number;
  userId: string;
  projectId: string;
  versionId: string;
  issue: string;
  message?: string;
  data?: Object;
}

export enum LogLevel {
  debug = 'debug',
  warn = 'warn',
  error = 'error',
}

// const ReporterApi = {
//   report(userId: string, payload: ReportPayload) {
//     return Api().post(`report/${userId}`, payload);
//   },
// };

class Logger {
  loggerIssue = LoggerIssue;

  warn = ({ issue, message, data }: { issue: string; message?: string; data?: Object }) => {
    console.log(`${this.dateString}: ${issue} ${message} ${data ? JSON.stringify(data) : ''}`);
    // this.report({ issue, message, data, level: 'warn' });
  };

  // devconsole = ({ issue, message, data }: { issue?: string; message?: string; data?: Object }) => {
  //   if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //     console.log(`${this.dateString}: ${issue ? JSON.stringify(issue) : ''} ${message} ${data ? JSON.stringify(data) : ''}`);
  //   }
  // };

  private get dateString(): string {
    return new Intl.DateTimeFormat('fi', { hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date());
  }

  logError = (err: unknown): void => {
    if (err instanceof Error) {
      console.error(`${this.dateString}: ${err.message}`);
    } else {
      console.error(`${this.dateString}: ${JSON.stringify(err)}`);
    }
  };

  log = (msg: any, level: LogLevel = LogLevel.debug) => {
    const message = (typeof msg === 'string' && msg) || (msg instanceof Error && msg.message) || JSON.stringify(msg);
    console.log(`${this.dateString}: ${message}`);
  };
}

const logger = new Logger();

export default logger;
