import Api from './Api';

interface SubscribeParams {
  userId: string;
  payload: {
    planType: 'monthly' | 'yearly';
    userId: string;
  };
}

interface UnsubscribeParams {
  userId: string;
}

interface UpdateSubscriptionParams {
  userId: string;
  payload: {
    type: 'reactivate' | 'deactivate' | 'changePlan';
    changeData?: {
      plan?: 'monthly' | 'yearly';
    };
  };
}

const service = {
  startSubscription({ userId, payload }: SubscribeParams) {
    return Api().post(`users/${userId}/subscription`, payload);
  },
  cancelSubscription({ userId }: UnsubscribeParams) {
    return Api().delete(`users/${userId}/subscription`);
  },
  getSubscription({ userId }: { userId: string }) {
    return Api().get(`users/${userId}/subscription`);
  },
  updateSubscription({ userId, payload }: UpdateSubscriptionParams) {
    return Api().put(`users/${userId}/subscription`, payload);
  },
};

export default service;
