import styled, { CSSProperties } from 'styled-components';
import { getLayoutClassName } from './Layout';
import { getSpaceCSSProperty, SpacingOption, Sx, transformSx } from './LayoutItem';

export const StyledStack = styled.div<{ cssProps: CSSProperties; childMargins: string | undefined }>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.cssProps.justifyContent};
  align-items: ${(props) => props.cssProps.alignItems};
  height: 100%;

  & > * {
    margin: ${(props) => props.childMargins};
  }

  & > .szen-fill-horizontal {
    align-items: stretch;
  }

  & > .szen-fill-vertical {
    flex-grow: 1;
  }
`;

interface Props extends React.ComponentProps<any> {
  children: React.ReactNode;
  className?: string;
  childMargins?: SpacingOption;
  fillHorizontal?: boolean;
  fillVertical?: boolean;
  horizontalAlign?: React.CSSProperties['alignItems'];
  style?: React.CSSProperties;
  sx?: Sx;
  verticalAlign?: React.CSSProperties['justifyContent'];
}

const Stack = (props: Props) => {
  const { children, className, childMargins, fillHorizontal, fillVertical, horizontalAlign, style, sx, verticalAlign, ...rest } = props;
  const transformedStyle: CSSProperties = {
    ...transformSx(props.sx),
    ...style,
  };

  return (
    <StyledStack
      {...rest}
      style={{ ...transformedStyle, ...props.style }}
      className={getLayoutClassName({ className, fillHorizontal, fillVertical })}
      childMargins={getSpaceCSSProperty(props.childMargins)}
      cssProps={{ justifyContent: props.verticalAlign ?? 'flex-start', alignItems: props.horizontalAlign ?? 'stretch' }}
    >
      {props.children}
    </StyledStack>
  );
};

export default Stack;
