import React, { useState } from 'react';
import styled from 'styled-components';
import Icon, { IconSize } from '../../icons/Icon';
import Typography from '../UI/Typography';
import useAuth from '../../helpers/useAuth';
import { useNavigate } from 'react-router-dom';
import { navigateToApp } from '../../helpers/navigationHelpers';
import useUser from '../../helpers/useUser';

const StyledHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledName = styled.div`
  /* font-size: 12px; */
  font-weight: bold;
  margin: 5px 0 5px 10px;
  /* vertical-align: middle; */
  /* border-bottom: 1px solid #ddd; */
`;

const StyledTop = styled.div`
  display: flex;
  --iconMainColor: rgb(200, 200, 200);
`;

const StyledAccountMenu = styled.div`
  /* width: 200px; */
`;

const StyledContents = styled.div`
  border: 1px solid #aaa;
  position: absolute;
  min-width: 180px;
  top: 40px;
  right: -10px;
  z-index: 400;
  padding: 10px 0px;
  background: white;
  padding: 8px;
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); */
  border-radius: 8px;
  box-shadow: var(--boxShadowContextMenu);

  ::before {
    content: '';
    position: absolute;
    top: -6px;
    right: 20px;
    height: 10px;
    width: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #aaa #aaa transparent transparent;
    background: white;
    transform: rotate(-45deg);
  }
`;

const StyledOption = styled.div`
  white-space: nowrap;
  cursor: pointer;
  padding: 4px 8px 4px 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &:hover {
    background: rgb(237, 237, 237);
  }
`;

interface Props {}

const AccountMenu = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  const { name } = useUser();
  const navigate = useNavigate();

  function goToProfile() {
    console.log('goToProfile');
    navigateToApp(navigate);
  }

  function doLogout() {
    auth.logoutUser();
  }

  function closeMenu(event: React.SyntheticEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();
    setIsOpen(false);
  }

  return (
    <StyledAccountMenu>
      <StyledHolder onClick={() => setIsOpen(true)}>
        <StyledTop>
          <Icon size={IconSize.large} iconName={'profile-filled'} filled={true} />
        </StyledTop>
        {isOpen && (
          <>
            <StyledContents>
              <Typography variant="managerItem">{name}</Typography>
              <StyledOption onClick={doLogout}>Logout</StyledOption>
              <StyledOption onClick={goToProfile}>Account</StyledOption>
            </StyledContents>
            <div onClick={closeMenu} className={'GlobalBackDropTransparent'} />
          </>
        )}
      </StyledHolder>
    </StyledAccountMenu>
  );
};

export default AccountMenu;

// iconColors={{ background: 'rgb(251,251,251)' }}
