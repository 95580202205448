import decode from 'jwt-decode';

export function getTokenExpirationDate(token: string) {
  const decoded = decode(token);
  if (typeof decoded === 'object' && decoded && 'exp' in decoded && typeof decoded.exp === 'number') {
    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    return date;
  } else {
    return null;
  }
}

export function isTokenExpired(token: string) {
  const date = getTokenExpirationDate(token);
  const offsetSeconds = 0;
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

function getTokenDetails(token: string) {
  const isExpired = isTokenExpired(token);
  const tokenData = decode<{ userId: string; actorId: string }>(token);
  const expiration = getTokenExpirationDate(token);
  return { ...tokenData, isExpired, expiration };
}

export function getTokenData() {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  return getTokenDetails(token);
}
