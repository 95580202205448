import styled from 'styled-components';
import { media } from '../styles';
import Icon from '../icons/Icon';

const StyledBurger = styled.div`
  display: none;
  width: 20px;

  ${media.forPhoneOnly`
    display: block;
  `}
`;

const StyledBar = styled.div`
  height: 2px;
  width: 16px;
  background: #ccc;
  margin: 4px 0;
`;

interface Props {
  toggleBurger: Function;
  isOpenBurger: boolean;
}

const Burger = (props: Props) => {
  const { isOpenBurger } = props;
  return isOpenBurger ? (
    <div onClick={() => props.toggleBurger(!isOpenBurger)}>
      <Icon iconName={'closecross'} />
    </div>
  ) : (
    <StyledBurger onClick={() => props.toggleBurger(!isOpenBurger)}>
      <StyledBar />
      <StyledBar />
      <StyledBar />
    </StyledBurger>
  );
};

export default Burger;
