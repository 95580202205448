import React, { useEffect, useState } from 'react';
import ContentService from '../../../services/ContentService';
import { CategoryType, TopicType } from '../../../types';
import TwoLevelPage from '../templates/TwoLevelPage';
// import { history } from '../../../RootApp';

interface Props {
  scrollTo: (p: { pos: number; noScroll?: true }) => void;
}

const LearnPage = (props: Props) => {
  const articleId = 'home'; // history.location.state ? history.location.state.articleId : 'home';
  const [topics, setTopics] = useState<null | TopicType[]>(null);

  useEffect(() => {
    getFeatureTopics();

    async function getFeatureTopics() {
      const response = await ContentService.getContent('instructions');
      const data = (await response.json()) as CategoryType;
      setTopics(data.topics);
    }
  }, []);

  return !topics ? null : (
    <TwoLevelPage
      initialActiveArticleId={articleId}
      scrollTo={props.scrollTo}
      title={'Features'}
      topics={topics}
    />
  );
};

export default LearnPage;
