import { Formik, Field, Form, ErrorMessage, FormikHelpers } from 'formik';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { StyledFormRow } from '../../../../styles';
import Layout from '../../Layout';
import { useCallback } from 'react';
import Box from '../../Box';
import Button from '../../Button/Button';
import useAuth from '../../../../helpers/useAuth';

interface FormValues {
  email: string;
  password: string;
  passwordAgain: string;
  betacode: string;
  agree: boolean;
}

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
  betacode: Yup.string().required('Required'),
});

const SignUpForm = () => {
  const { signUpUser } = useAuth();

  const submitSignUp = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      const payload = {
        data: {
          email: values.email,
          password: values.password,
          betacode: values.betacode,
        },
        cb: errorCallback,
      };

      function errorCallback(msg?: string) {
        helpers.setSubmitting(false);
        helpers.setStatus({ message: msg ?? `Signing up did not succeed.` });
      }

      signUpUser(payload);
    },
    [signUpUser]
  );

  return (
    <Formik
      initialValues={{ email: '', password: '', passwordAgain: '', betacode: '', agree: false }}
      onSubmit={submitSignUp}
      validationSchema={SignupSchema}
      validateOnBlur={false}
    >
      {({ errors, status, touched, isSubmitting, isValid, values: currentValues }) => (
        <Form translate={undefined} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <StyledFormRow>
            <label htmlFor="email">
              <Field
                autoFocus
                autoComplete={'username'}
                className={'site--form-text-field'}
                data-testid="email"
                placeholder={'Email'}
                type={'email'}
                name={'email'}
              />
            </label>
            <ErrorMessage className={'form--error-message'} name={'email'} component={'div'} />
          </StyledFormRow>
          <StyledFormRow>
            <label htmlFor="password">
              <Field
                autoComplete={'new-password'}
                className={'site--form-text-field'}
                data-testid="password"
                placeholder={'Password'}
                type={'password'}
                name={'password'}
              />
            </label>
            <ErrorMessage className={'form--error-message'} name={'password'} component={'div'} />
          </StyledFormRow>
          <StyledFormRow>
            <label htmlFor="passwordAgain">
              <Field
                className={'site--form-text-field'}
                data-testid="passwordAgain"
                placeholder={'Password again'}
                type={'password'}
                name={'passwordAgain'}
              />
              <ErrorMessage className={'form--error-message'} name={'passwordAgain'} component={'div'} />
            </label>
          </StyledFormRow>
          <StyledFormRow>
            <label htmlFor="passwordAgain">
              <Field className={'site--form-text-field'} placeholder={'Beta Signup Code'} type={'password'} name={'betacode'} />
              <ErrorMessage className={'form--error-message'} name={'passwordAgain'} component={'div'} />
            </label>
          </StyledFormRow>
          <StyledFormRow>
            <label htmlFor="agree">
              <Layout sx={{ margin: 'horizontal-large', padding: 'horizontal-medium' }}>
                <Field data-testid="agree" type={'checkbox'} name={'agree'} />
                <span style={{ display: 'inline-block', marginLeft: '8px' }}>
                  I agree to the{' '}
                  <NavLink to={'/terms'} target={'_blank'}>
                    Terms of Service
                  </NavLink>{' '}
                  and{' '}
                  <NavLink to={'/privacy'} target={'_blank'}>
                    Privacy Policy
                  </NavLink>
                  .
                </span>
              </Layout>
              <ErrorMessage name={'agree'} component={'div'} />
            </label>
          </StyledFormRow>
          <Button
            type="submit"
            disabled={isSubmitting || !isValid || currentValues.agree !== true || Object.values(currentValues).some((v) => !v)}
            buttonType={'site'}
          >
            Submit
          </Button>
          <Box variant="row" style={{ display: 'flex', alignItems: 'center' }}>
            {status && status.message && <div>{status.message}</div>}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
