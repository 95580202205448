import axios from 'axios';
import { OutgoingHttpHeaders } from 'http';

export const contentBaseURL = `${process.env.REACT_APP_contentBaseURL}`;

const api = () => {
  const headers: OutgoingHttpHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  return axios.create({
    baseURL: contentBaseURL,
    headers: headers,
  });
};

export default api;
