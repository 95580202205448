import Button from '../../Button/Button';
import { Formik, Field, Form, ErrorMessage, FormikErrors } from 'formik';
import AuthenticationService from '../../../../services/AuthenticationService';
import { StyledFormBlock, StyledFormRow } from '../../../../styles';

interface MyFormValues {
  email: string;
}

interface Props {
  responseHandler: (status: 'done' | 'error') => void;
}

const LogInForm = (props: Props) => {
  async function submitReset(values: MyFormValues) {
    const payload = {
      email: values.email,
    };
    const response = await AuthenticationService.reset(payload);
    if (response.status === 200) {
      return props.responseHandler('done');
    }
    return props.responseHandler('error');
  }

  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={submitReset}
        validate={(values: MyFormValues) => {
          let errors: FormikErrors<MyFormValues> = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        render={({ errors, status, touched, isSubmitting }) => (
          <Form translate={undefined} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StyledFormBlock>We will send a password reset link to the email address.</StyledFormBlock>
            <StyledFormRow>
              <label htmlFor="email">
                <Field autoFocus type={'email'} name={'email'} placeholder={'Email'} />
                <ErrorMessage name={'email'} component={'div'} />
              </label>
              {status && status.message && <div>{status.message}</div>}
            </StyledFormRow>
            <Button type="submit" disabled={isSubmitting} buttonType={'site'}>
              Reset
            </Button>
          </Form>
        )}
      />
    </>
  );
};

export default LogInForm;
