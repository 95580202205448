import styled, { CSSProperties } from 'styled-components';
import { Sx, transformSx } from './LayoutItem';

const StyledBox = styled.div`
  /* display: flex; */
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  sx?: Sx;
  variant?: 'small' | 'medium' | 'large' | 'row';
}

const Box = (props: Props) => {
  const { variant } = props;
  const heights = {
    row: '48px',
    small: '300px',
    medium: '600px',
    large: '900px',
  };

  const style: CSSProperties = {
    ...transformSx(props.sx),
  };

  return (
    <StyledBox style={{ ...style, ...props.style, height: variant ? heights[variant] : undefined }} className={props.className}>
      {props.children}
    </StyledBox>
  );
};

export default Box;
