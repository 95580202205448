import styled from 'styled-components';

const StyledPageNav = styled.div`
  position: absolute;
  /* border: 1px solid gray; */
  top: 100px;
  /* margin-top: 100px; */
  /* margin-right: 40px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* flex: 1 1 20%; */
`;

interface NavProps {
  isActive: boolean;
}

const StyledNavItem = styled.div<{ height: number }>`
  margin-top: 5px;
  /* height: ${(props) => `${props.height}px`}; */
  transition: all 1s;
  overflow-y: hidden;
  /* background-color: ${(props) => (props.height < 100 ? 'white' : '#DDD')}; */
`;

const StyledNavItemTitle = styled.div<NavProps>`
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  font-family: var(--baseFont);
  font-size: 20px;
  color: black;
  margin-bottom: 5px;
  cursor: pointer;
  letter-spacing: 1px;
`;

const StyledChildNavItem = styled.div<NavProps>`
  margin-top: 8px;
  font-size: 18px;
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  font-family: Lato;
  color: black;
  padding-left: 10px;
  cursor: pointer;
  letter-spacing: 0.5px;
`;

interface Props {
  activeItem: string;
  activeArticleIndex: number;
  items: {
    title: string;
    id: string;
    children: {
      id: string;
      title: string;
    }[];
  }[];
  onChange: (s: string) => void;
  changeActiveArticle: (s: number) => void;
}

const TwoLevelPageNav = (props: Props) => {
  const { activeItem, activeArticleIndex, items, onChange, changeActiveArticle } = props;

  const d_items = !items
    ? null
    : items.map((item) => {
        const isActive = item.id === activeItem;

        const d_children = !isActive
          ? null
          : item.children.map((child, index) => {
              return (
                <StyledChildNavItem
                  key={child.id}
                  isActive={index === activeArticleIndex}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    changeActiveArticle(index);
                  }}
                >
                  {child.title}
                </StyledChildNavItem>
              );
            });

        const height = isActive ? item.children.length * 30 + 30 : 30;

        return (
          <StyledNavItem height={height} key={item.id} onClick={() => onChange(item.id)}>
            <StyledNavItemTitle isActive={isActive}>{item.title}</StyledNavItemTitle>
            {d_children}
          </StyledNavItem>
        );
      });

  return <StyledPageNav>{d_items}</StyledPageNav>;
};

export default TwoLevelPageNav;
