import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import TopicPage from './TopicPage';
import { TopicType } from '../../../types';
import { media } from '../../../styles';
import TwoLevelPageNav from './TwoLevelPageNav';

const StyledPageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledPageGrid = styled.div`
  display: block;
  margin: 0 auto;
  display: grid;
  grid-template-columns: min-content 1fr;
`;

const StyledConstainer = styled.div`
  max-width: 1000px;
  width: 100%;
  display: flex;
`;

const StyledMain = styled.div`
  margin-top: 40px;

  @media only screen and (max-width: 768px) {
  }
`;

const BlurBar = styled.div`
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  height: 48px;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.7);
`;

const StyledBackTitle = styled.div`
  font-size: 16px;
  margin-left: 5px;
  text-transform: uppercase;
`;

const StyledMobileBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  position: sticky;
  top: 20px;
  left: 0px;
  margin-right: 30px;

  ${media.forPhoneOnly`
    display: flex;
    width: 100%;
    /* justify-content: flex-end;    */
  `};

  :hover {
    transform: translateY(-2px);
  }
`;

interface Props {
  title: string;
  topics: TopicType[];
  scrollTo: (p: { pos: number; noScroll?: true }) => void;
  initialActiveArticleId?: string;
}

const TwoLevelPage = (props: Props) => {
  const { topics, initialActiveArticleId, scrollTo } = props;
  const [activeTopicInd, activeArticleInd] = getTopicAndArticleIndex();
  const [activeTopicIndex, setActiveTopicIndex] = useState(0); // useState(() => (activeTopicInd !== -1 ? activeTopicInd : -1));
  const [activeArticleIndex, setActiveArcticleIndex] = useState(activeArticleInd);
  const [articleRefArray, setArticleRefArray] = useState<any>(null);

  useEffect(() => {
    if (articleRefArray && activeArticleIndex !== 0) {
      const article = articleRefArray[activeArticleIndex];
      if (article) {
        const position: number = articleRefArray[activeArticleIndex].current.offsetTop;
        scrollTo({ pos: position - 100 });
      }
    }
    console.log(articleRefArray);
  }, [articleRefArray, scrollTo, activeArticleIndex]);

  const handleArticleRefArray = useCallback((array: any[]) => {
    // if (activeArticleIndex !== 0) {
    //   const position: number = array[activeArticleIndex].current.offsetTop;
    //   props.scrollTo(position);
    // }
    setArticleRefArray(array);
  }, []);

  const navItems = topics.map((topic) => {
    const children = topic.articles.map((article) => {
      return { id: article.id, title: article.title };
    });
    return { title: topic.title, id: topic.id, children: children };
  });

  function getTopicAndArticleIndex() {
    const topicIndex = topics.findIndex((topic) => {
      const index = topic.articles.findIndex((article) => {
        return article.title.toLowerCase() === initialActiveArticleId;
      });
      return index >= 0 ? true : false;
    });

    const articleIndex =
      topicIndex >= 0
        ? topics[topicIndex].articles.findIndex((article) => article.title.toLocaleLowerCase() === initialActiveArticleId)
        : 0;
    return [topicIndex, articleIndex];
  }

  const changeTopic = useCallback(
    (id: string) => {
      const topicIndex = topics.findIndex((topic) => topic.id === id);
      setActiveArcticleIndex(0);
      setActiveTopicIndex(topicIndex);
      scrollTo({ pos: 0, noScroll: true });
    },
    [topics, scrollTo]
  );

  const changeArticle = useCallback(
    (index: number) => {
      setActiveArcticleIndex(index);
      if (articleRefArray) {
        const position: number = articleRefArray[index].current.offsetTop;
        scrollTo({ pos: position - 80 }); // takes into account article page layout
      }
    },
    [articleRefArray, scrollTo]
  );

  // function setMobileArticle(topicIndex: number, articleIndex: number) {
  //   setActiveTopicIndex(topicIndex);
  //   setActiveArcticleIndex(articleIndex);
  //   scrollTo({ pos: 0, noScroll: true });
  // }

  if (!topics) return null;

  // if (activeTopicIndex !== -1) {
  //   return (
  //     // <StyledPageContainer>
  //     // <StyledConstainer>
  //     <StyledPageGrid>
  //       <div>
  //         <StyledMobileBack onClick={() => setMobileArticle(-1, -1)}>
  //           <Icon iconName={'triangle-left-filled'} height={14} iconColors={{ fillColor: 'black' }} />
  //           <StyledBackTitle>Index</StyledBackTitle>
  //         </StyledMobileBack>
  //       </div>
  //       <StyledMain>
  //         <ArticlesPage
  //           topic={topics[activeTopicIndex]}
  //           setArticleRefArray={handleArticleRefArray}
  //           // changeActiveArticle={setMobileArticle}
  //         />
  //       </StyledMain>
  //     </StyledPageGrid>
  //     // </StyledConstainer>
  //     // </StyledPageContainer>
  //   );
  // }

  return (
    <StyledPageContainer>
      <StyledConstainer>
        <StyledPageGrid>
          <BlurBar className="BLURBAR" />
          {/* <TwoLevelNavCards topics={topics} changeActiveArticle={setMobileArticle} /> */}
          <div style={{ width: '280px' }}>
            <TwoLevelPageNav
              activeItem={topics[activeTopicIndex].id}
              onChange={changeTopic}
              items={navItems}
              activeArticleIndex={activeArticleIndex}
              changeActiveArticle={changeArticle}
            />
          </div>
          <StyledMain>
            <TopicPage topic={topics[activeTopicIndex]} setArticleRefArray={handleArticleRefArray} />
          </StyledMain>
        </StyledPageGrid>
      </StyledConstainer>
    </StyledPageContainer>
  );
};

export default TwoLevelPage;
