import styled from 'styled-components';
import { media } from '../../../../styles';
import useAuth from '../../../../helpers/useAuth';
import useSubscription from '../../../../helpers/useSubscription';
import Button from '../../../../components/UI/Button/Button';
import useUser from '../../../../helpers/useUser';
import { ZSubscriptionStatus } from '@sandboxsurfer/szen-messages';

const StyledPlan = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 360px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px, rgba(0, 0, 0, 0.1) 0px 20px 30px;
  background-color: rgb(67, 138, 255);
  color: white;
  border-radius: 20px;
  margin: 0 30px;
  padding-bottom: 20px;

  ${media.forPhoneOnly`
    grid-column: span 4;
    padding: 0 20px;
  `}
`;
export const StyledPlanTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  padding-top: 5px;
  height: 40px;
  margin-bottom: 20px;
`;

const StyledPrice = styled.div`
  font-size: 32px;
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

const StyledUnit = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
`;

const StyledDescription = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 20px 0px;
  font-size: 18px;
`;

const StyledFeature = styled.div`
  display: flex;
  justify-content: center;
`;

export interface PlanType {
  id: 'monthly' | 'yearly';
  title: string;
  description: string;
  price: string;
  unit: string;
  actionTitle: string;
  features: string[];
}

interface Props {
  plan: PlanType;
}

const Plan = (props: Props) => {
  const { id, title, description, actionTitle, price, unit, features } = props.plan;
  const auth = useAuth();
  const subscription = useSubscription();
  const { subscriptionStatus } = useUser();
  const showSubscribeButton =
    subscriptionStatus === ZSubscriptionStatus.Enum.deactive ||
    subscriptionStatus === ZSubscriptionStatus.Enum.trial ||
    subscriptionStatus === ZSubscriptionStatus.Enum.trialExpired;

  function goSubscribe(plan: 'monthly' | 'yearly') {
    subscription.subscribe({
      planType: plan,
    });
  }

  return (
    <StyledPlan>
      <div>
        <StyledPlanTitle>{title}</StyledPlanTitle>
        <StyledPrice>{price}</StyledPrice>
        <StyledUnit>{unit}</StyledUnit>
        <StyledDescription>{description}</StyledDescription>
        {features.map((feature) => (
          <StyledFeature key={feature.slice(1, 10)}>{feature}</StyledFeature>
        ))}
      </div>
      {showSubscribeButton && (
        <Button buttonType={'site'} clicked={() => goSubscribe(id)}>
          {actionTitle}
        </Button>
      )}
    </StyledPlan>
  );
};

export default Plan;
