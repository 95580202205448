import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// import { ReactComponent as Tracks } from '../../../assets/svg/tracks.svg';

const StyledDisplay = styled.div`
  /* position: absolute; */
  /* z-index: 10; */
  /* left: 400px; */
  /* height: 400px; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 20px, rgba(0, 0, 0, 0.2) 0 0 1px;
  min-width: 860px;
  min-height: 690px;
  border-radius: 4px;
  width: 100%;
  max-width: 700px;
  padding: 20px 0;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px;
  background-color: rgba(255, 255, 255, 0.1); */
`;

interface Props {
  // children?: React.ReactElement;
  images: string[];
}

const ExperimentalDisplay = (props: Props) => {
  const [images] = useState(props.images);
  const [index, setIndex] = useState(0);

  // const imagePath = images[index];
  // useEffect(() => {
  //   const vallu = setInterval(function(i: number) {
  //     if (indexRef.current === images.length - 1) {
  //       indexRef.current = 0;
  //     } else {
  //       indexRef.current = indexRef.current + 1;
  //     }
  //   }, 2000);
  //   return () => clearInterval(vallu);
  // }, []);

  useEffect(() => {
    const vallu = setInterval(function () {
      setIndex((currIndex) => (currIndex === images.length - 1 ? 0 : currIndex + 1));
    }, 10000);
    return () => clearInterval(vallu);
  }, [images.length]);

  // if (imagePath.includes('tracks')) {
  //   return (
  //     <StyledDisplay>
  //       <Tracks />
  //     </StyledDisplay>
  //   );
  // }

  return (
    <StyledDisplay>
      <img
        style={{ width: '100%', height: 'auto' }}
        src={`img/${images[index]}`}
        alt={'people on the road'}
      />
    </StyledDisplay>
  );
};

export default ExperimentalDisplay;
