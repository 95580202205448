import LegalNav from '../Pages/LegalNav';
import { StyledPageContainer, StyledMain, StyledPageTitle, StyledContent } from './SimplePage';
import styled from 'styled-components';

interface Props {
  title: string;
  children: any;
}

export const StyledPage = styled.div`
  display: inline-flex;
  max-width: 700px;
  justify-content: center;
  /* width: 100%; */
`;

const StyledHolder = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
`;

const LegalPage = ({ title, children }: Props) => {
  return (
    <StyledPageContainer>
      <StyledHolder>
        <LegalNav />
        <StyledPage>
          <StyledMain>
            <StyledPageTitle>{title}</StyledPageTitle>
            <StyledContent>{children}</StyledContent>
          </StyledMain>
        </StyledPage>
      </StyledHolder>
    </StyledPageContainer>
  );
};

export default LegalPage;
