// import escapeHtml from 'escape-html';
import { Node, Text } from 'slate';
import { ReactElement } from 'react';

export const serialize = (node: Node, index: number): string | ReactElement => {
  if (Text.isText(node)) {
    // return escapeHtml(node.text);
    return node.text;
  }
  const children = node.children.map((n, i) => serialize(n, i)).join('');

  switch (node.type) {
    case 'heading-one':
      return (
        <h1 className="content" key={index}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 className="content" key={index}>
          {children}
        </h2>
      );
    case 'heading-three':
      return <h3 key={index}>{children}</h3>;
    case 'quote':
      return (
        <blockquote key={index}>
          <p>{children}</p>
        </blockquote>
      );
    case 'bulleted-list':
      const blc = node.children.map((n, i) => serialize(n, i));
      return (
        <ul key={index} className={'site-list'}>
          {blc}
        </ul>
      );
    case 'list-item':
      return (
        <li key={index} className={'site-listitem'}>
          {children}
        </li>
      );
    case 'paragraph':
      return (
        <p key={index} className={'site-paragraph'}>
          {children}
        </p>
      );
    case 'link':
      return (
        <a key={index} href={`${node.url}`}>
          {children}
        </a>
      );
    case 'image':
      return <img key={index} className={'site-image'} src={`img/${node.url}`} alt="" />;
    default:
      return children;
  }
};
