import { ILoginRes } from '@sandboxsurfer/szen-messages';
import React, { useState } from 'react';
import { svLocalStorage } from './localstorage';

export type AuthState = Omit<ILoginRes, 'token'> | null;

interface AuthContext {
  auth: AuthState;
  setAuthData: (update: ILoginRes | null) => void;
}

const initialAuthState: AuthState = null;

export const AuthContext = React.createContext<AuthContext>({ auth: initialAuthState, setAuthData: () => {} });

interface Props {
  children: React.ReactNode;
}

const AuthContextProvider = ({ children }: Props) => {
  const [authState, setAuthState] = useState<AuthState>(initialAuthState);

  const setAuthData = (update: ILoginRes | null) => {
    if (update !== null) {
      const { token, ...rest } = update;
      localStorage.setItem('token', token);
      setAuthState(rest);
    } else {
      svLocalStorage.clearToken();
      setAuthData(null);
    }
  };

  const authValue = {
    auth: authState,
    setAuthData,
  };

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
