import styled from 'styled-components';

const StyledBurgerMenu = styled.div`
  width: 100%;
  /* height: 500px; */
  /* border: 3px solid orange; */
  padding: 10px 20px;
`;

interface Props {
  toggleBurger: Function;
  // items: any[]
  children: React.ReactElement | React.ReactElement[];
}

const BurgerMenu = (props: Props) => {
  return <StyledBurgerMenu>{props.children}</StyledBurgerMenu>;
};

export default BurgerMenu;
