import styled from 'styled-components';
import siteLogo from '../../assets/logo.svg';
import { useNavigate } from 'react-router-dom';

const StyledLogo = styled.div`
  align-items: center;
  max-width: 100px;
  height: 32px;
  cursor: pointer;

  > img {
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    height: 20px;
  }
`;

interface Props {
  toggleBurger?: Function;
  isOpenBurger?: boolean;
}

const Logo = (props: Props) => {
  const { isOpenBurger, toggleBurger } = props;
  const navigate = useNavigate();
  function handleLogoClick() {
    navigate('/');
    if (isOpenBurger && toggleBurger) {
      toggleBurger();
    }
  }

  return (
    <StyledLogo onClick={handleLogoClick}>
      <img src={siteLogo} alt="SiteLogo" />
    </StyledLogo>
  );
};

export default Logo;
