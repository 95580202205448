import { useState } from 'react';
import SimplePage from '../templates/SimplePage';
import ResetPasswordForm from '../../../components/UI/Forms/AuthForms/ResetPasswordForm';
import Dialogue from '../../../components/Dialogue';
import styled from 'styled-components';

const StyledFooter = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: blue;
`;

const ResetPasswordPage = () => {
  const [status, setStatus] = useState<undefined | 'done' | 'error'>();

  const form = (
    <Dialogue title={'Reset password'}>
      <ResetPasswordForm responseHandler={(status) => setStatus(status)} />
      <StyledFooter></StyledFooter>
    </Dialogue>
  );

  const doneContent = (
    <Dialogue title={'Reset password'}>
      <div>Please check you email for instructions.</div>
    </Dialogue>
  );

  const errorContent = (
    <Dialogue title={'Reset password'}>
      <div>We are sorry.</div>
      <div>There was a technical error while processing the request.</div>
      <div>Please try again shortly or contact </div>
    </Dialogue>
  );

  function getContent() {
    switch (status) {
      case undefined:
        return form;
      case 'done':
        return doneContent;
      case 'error':
        return errorContent;
      default:
        return <div></div>;
    }
  }

  return <SimplePage title={''}>{getContent()}</SimplePage>;
};

export default ResetPasswordPage;
