import styled from 'styled-components';

export const StandardManagerTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 5px; */
  margin-bottom: 4px;
  text-transform: uppercase;
  padding-right: 16px;
`;

export const StandardSubTitle = styled.div`
  margin-bottom: 4px;
  /* margin-top: 10px; */
  text-transform: uppercase;

  :first-of-type {
    margin-top: 0px;
  }
`;

export const StandardFlexRow = styled.div`
  display: flex;
  justify-content: flex-start;

  > * {
    margin-right: 8px;
  }
`;

export * from './formAndInput';
export * from './media';
export * from './dash';
