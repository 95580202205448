import styled from 'styled-components';
import Plan, { PlanType, StyledPlanTitle } from './Plan';
import SimplePage from '../../templates/SimplePage';
import Button from '../../../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import { navigateToApp } from '../../../../helpers/navigationHelpers';
import useUser from '../../../../helpers/useUser';
import { ZSubscriptionStatus } from '@sandboxsurfer/szen-messages';

const StyledUnit = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  grid-column: 1/5;
`;

const StyledTrialInfo = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px, rgba(0, 0, 0, 0.1) 0px 20px 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  margin: 0 20px;
  grid-column: 1/5;
  padding: 0 20px 20px;
`;

const StyledPlanDescription = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 18px;
`;

const StyledInterlude = styled.div`
  grid-column: span 4;
  margin: 40px 0;
  display: flex;
  justify-content: center;
  font-size: 16px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-row-gap: 20px;
  width: 100%;
  margin-top: 40px;
`;

const plans: PlanType[] = [
  {
    id: 'monthly',
    title: 'Montly plan',
    description: 'Billed each month. Makes $96 a year.',
    price: '$8',
    unit: 'per month',
    actionTitle: 'Sign up',
    features: ['All features included.'],
  },
  {
    id: 'yearly',
    title: 'Yearly plan',
    description: 'Billed yearly. Save 27% compared to monthly plan.',
    price: '$70',
    unit: 'per month',
    actionTitle: 'Sign up',
    features: ['All features included.'],
  },
  // {
  //   title: 'Free Trial',
  //   description: 'No commitment. No credit card. Just 30 days to try out Storyzen.',
  //   price: 8,
  //   unit: 'per month',
  //   actionTitle: 'Start trial'
  // }
];

const PricingPage = () => {
  const { isLoggedIn, subscriptionStatus } = useUser();
  const navigate = useNavigate();

  function signUpForBeta() {
    navigate('/betasignup');
  }

  function goToSettings() {
    navigateToApp(navigate);
  }

  return (
    <SimplePage title={`Storyvera Plans`}>
      <StyledGrid>
        <StyledTrialInfo>
          {/* <StyledPlanTitle>Free Trial</StyledPlanTitle> */}
          {/* <StyledPlanDescription>
            No commitment. No credit card. Just 30 days to try out Storyzen.
          </StyledPlanDescription> */}
          <StyledPlanTitle>Free during Beta</StyledPlanTitle>
          <StyledPlanDescription>Free is free to use during beta phase.</StyledPlanDescription>
          <StyledPlanDescription>During beta everything is subject to constant change.</StyledPlanDescription>
          {/* {!isUserLoggedIn && (
            <Button buttonType={'site'} clicked={startTrial}>
              Create an Account
            </Button>
          )} */}
          {/* {!isUserLoggedIn && (
            <Button buttonType={'site'} clicked={startTrial}>
              Start 30-day Free Trial
\            </Button>
          )} */}
          {!isLoggedIn && (
            <Button buttonType={'site'} clicked={signUpForBeta}>
              Sign up for Beta
            </Button>
          )}
        </StyledTrialInfo>
        {/* <StyledInterlude>
          After trial to continue using Storyzen, you can choose between the following pricing
          plans:
        </StyledInterlude> */}
        <StyledInterlude>After beta phase is over, you can choose between the following pricing plans:</StyledInterlude>
        {plans.map((plan) => (
          <Plan key={plan.title} plan={plan} />
        ))}
        <StyledUnit>
          {/* <div>Some really clever information here.</div> */}
          {subscriptionStatus === ZSubscriptionStatus.Enum.pendingDeactivation && (
            <Button buttonType={'site'} clicked={goToSettings}>
              Reactivate your subscription
            </Button>
          )}
        </StyledUnit>
      </StyledGrid>
    </SimplePage>
  );
};

export default PricingPage;
