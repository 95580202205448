import styled from 'styled-components';
import { media } from './media';

export const StyledDashContentContainer = styled.div`
  height: calc(100% - 54px);
  overflow-y: scroll;
`;

export const StyledDashHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 54px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid var(--viewBorderColor);
`;

export const StyledDashListDisplay = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${media.forPhoneOnly`
    justify-content: center;
  `}
`;

export const StyledDashContainer = styled.div`
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledDashCardRow = styled.div<{ height?: number }>`
  max-height: ${(props) => `${props.height}px`};
  margin: 4px 0 4px 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StandardDashCardTop = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--panelBorderColor);
`;
