import ContentApi, { contentBaseURL } from './ContentApi';

const service = {
  getAllTopics() {
    return ContentApi().get(`topics/`);
  },
  getOneTopic(topicId: string) {
    const foo = ContentApi().get(`topics/${topicId}`);
    return foo;
  },
  getOneArticle(articleId: string) {
    const foo = ContentApi().get(`articles/${articleId}`);
    return foo;
  },
  getContent(contentId: string) {
    console.log('getContent', contentId);
    console.log('contentBaseURL', contentBaseURL);
    // return fetch(`${contentBaseURL}/${contentId}.json`);
    return fetch(`${contentBaseURL}/${contentId}.json`);
  },
};

export default service;
