import React, { ReactChild } from 'react';
import styled from 'styled-components';
import Stack from './UI/Stack';

const StyledDialogue = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 200px 0;
  padding: 20px 0;
  max-width: 800px;
  min-width: 400px;
  border: 1px solid var(--site--border-colour);
  border-radius: 12px;
`;

const StyledTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-family: Lato;
  margin: 30px 0 20px 0;
`;

interface Props {
  title?: string;
  children: React.ReactNode;
}

const Dialogue = (props: Props) => {
  return (
    <StyledDialogue>
      <StyledTitle>{props.title}</StyledTitle>
      <Stack sx={{ margin: 'vertical-large' }} horizontalAlign={'center'}>
        {props.children}
      </Stack>
    </StyledDialogue>
  );
};

export default Dialogue;
