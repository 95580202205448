import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Sx, transformSx } from './LayoutItem';

const ManagerHeader = styled.span`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  user-select: none;
  margin-left: 0px;
  margin-right: 8px;
  overflow-x: hidden;
  white-space: nowrap;
  letter-spacing: 0.5px;
`;

const ManagerItem = styled.span<{ active?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: ${(props) => (props.active ? 'bold' : undefined)};
  font-size: 14px;
`;

const FormTitle = styled.div`
  margin-bottom: 4px;
  font-size: 12px;
  color: var(--panelTextColor);
`;

const ManagerTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const ManagerText = styled.span`
  color: var(--appTextColorSecondary);
`;

const ContentTitle = styled.div`
  color: var(--appTextColorPrimary);
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const ContentDetail = styled.div`
  color: var(--appTextColorSecondary);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
`;

const Link = styled.a``;

export const TypoVariant = {
  managerHeader: 'managerHeader',
  managerItem: 'managerItem',
  managerText: 'managerText',
  managerTitle: 'managerTitle',
  formTitle: 'formTitle',
  contentTitle: 'contentTitle',
  contentDetail: 'contentDetail',
  button: 'button',
  link: 'link',
} as const;

type TypoKeys = keyof typeof TypoVariant;
type TypoValue = (typeof TypoVariant)[TypoKeys];

interface Props {
  variant: TypoValue;
  children?: string;
  sx?: Sx;
  style?: CSSProperties;
  isAction?: boolean;
}

const Typography = ({ variant, children, sx, style = {}, isAction = false }: Props) => {
  const styles: CSSProperties = {
    ...transformSx(sx),
    ...style,
  };

  if (!children) return null;

  switch (variant) {
    case TypoVariant.formTitle:
      return <FormTitle style={styles}>{children}</FormTitle>;
    case TypoVariant.managerTitle:
      return (
        <ManagerTitle style={styles} className={isAction ? 'typography-action' : undefined}>
          {children}
        </ManagerTitle>
      );
    case TypoVariant.managerHeader:
      return <ManagerHeader style={styles}>{children}</ManagerHeader>;
    case TypoVariant.managerItem:
      return <ManagerItem style={styles}>{children}</ManagerItem>;
    case TypoVariant.managerText:
      return <ManagerText style={styles}>{children}</ManagerText>;
    case TypoVariant.contentTitle:
      return (
        <ContentTitle style={styles} className={isAction ? 'typography-action' : undefined}>
          {children}
        </ContentTitle>
      );
    case TypoVariant.contentDetail:
      return <ContentDetail style={styles}>{children}</ContentDetail>;
    case TypoVariant.link:
      return <Link style={styles}>{children}</Link>;
    default:
      return <span>{children}</span>;
  }
};

export default Typography;
