import styled from 'styled-components';
import { media } from '../../../styles';
import Display from './Display';
import { LandingId } from './Landingdata';
import { useNavigate } from 'react-router-dom';

export const StyledRow = styled.div<{ backgroundColor: 'light' | 'dark' }>`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  background-color: ${(props) => (props.backgroundColor === 'light' ? 'white' : 'rgb(245,245,245)')};

  ${media.forTabletLandscapeUp`
  /* min-height: 640px; */
  margin-top: 200px;
  
  `}

  ${media.forDesktopUp`
    width: 1240px;
    min-height: 680px;
  `}
`;

export const StyledWidthContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.forTabletLandscapeUp`
    /* flex-direction: row; */
    /* align-items: center; */
    `}
`;

interface StyledProps {
  order: number;
}

const StyledTextContent = styled.div<StyledProps>`
  padding: 10px 0px;
  order: ${(props) => props.order};
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-right: ${(props) => (props.order === 0 ? '80px' : undefined)};
  margin-left: ${(props) => (props.order === 1 ? '80px' : undefined)};
  align-items: center;
  justify-content: center;

  ${media.forPhoneOnly`
    order: 0;
    width: 100%;
    padding: 0 24px 0 24px;
    margin: 0 0 0 0;
  `}
`;

const StyledTitle = styled.div`
  margin: 20px 0 20px 0;
  color: black;
  font-family: 'Source Sans Pro', Poppings, 'Montserrat', 'Lato', Soleil;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  align-self: flex-start;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  ${media.forTabletLandscapeUp`
    font-size: 48px;
  `}
`;

const StyledText = styled.div`
  margin: 0px 0 20px 0;
  font-family: Soleil, Acme, Raleway, Lato;

  color: black;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;

  ${media.forTabletLandscapeUp`
     margin: 20px 0 20px 0;
    font-size: 24px;
    `}

  ${media.forDesktopUp`
    font-size: 24px;
    `}
`;

const StyledLink = styled.div`
  font-family: Soleil;
  color: #0055ff;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;

  :hover {
    transform: translateY(-2px);
  }

  ${media.forTabletLandscapeUp`
    font-size: 24px;
    align-self: flex-start;
    `}
`;

const StyledImageHolder = styled.div<StyledProps>`
  position: relative;
  order: ${(props) => props.order};
  display: flex;
  justify-content: center;
  flex: 1 0 auto;

  ${media.forPhoneOnly`
    order: 1;
    /* width: 100%; */
  `}
`;

const StyledRowContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.forTabletLandscapeUp`
    flex-direction: row;
  `}
`;

interface Props {
  data: {
    id: LandingId;
    title: string;
    subtitle: string;
    images: string[];
    imageAlt: string;
    text: string;
    // topicId: string;
    textOn: 'right' | 'left';
    backgroundColor: 'light' | 'dark';
  };
  index: number;
}

const Row = (props: Props) => {
  const { title, images, text, id, textOn, backgroundColor } = props.data;
  const navigate = useNavigate();

  function seeHowClicked(articleId: string) {
    // TODO: Replace with query string?
    navigate('/features');

    // dispatch(
    //   navigateWithRouter({
    //     path: '/features',
    //     stateUpdate: {
    //       inputTarget: {
    //         type: 'state',
    //         targetArea: 'general',
    //         targetApp: 'dash',
    //         targetFeature: 'dashItemId',
    //       },
    //       value: articleId,
    //     },
    //   })
    // );
  }

  return (
    <StyledRow backgroundColor={backgroundColor}>
      <div className={'site-fillerup'}>
        <StyledWidthContainer>
          {/* <StyledSubtitle>{subtitle}</StyledSubtitle> */}
          <StyledRowContent>
            <StyledTextContent order={textOn === 'right' ? 0 : 1}>
              <StyledTitle data-testid="title">{title}</StyledTitle>
              <StyledText>{text}</StyledText>
              <StyledLink onClick={() => seeHowClicked(id)}>See how</StyledLink>
            </StyledTextContent>
            <StyledImageHolder order={textOn === 'right' ? 1 : 0}>
              {/* <StyledRowImage src={imageSrc} alt={imageAlt} /> */}
              <Display images={images} />
            </StyledImageHolder>
          </StyledRowContent>
        </StyledWidthContainer>
      </div>
    </StyledRow>
  );
};

export default Row;
