import { AxiosResponse } from 'axios';
import logger from '../services/LoggerService';
import { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import SubscriptionService from '../services/SubscriptionService';
import useUser from './useUser';

export interface SubscribeStartPayload {
  planType: 'monthly' | 'yearly';
}

export interface SubscriptionUpdateStartPayload {
  type: 'reactivate' | 'deactivate';
}

declare global {
  interface Window {
    // add you custom properties and methods
    // foobar: any;
    Stripe: any;
  }
}

const useSubscription = () => {
  const { userId } = useUser();

  const stripe =
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY && window.Stripe !== undefined
      ? window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
      : undefined;

  const subscribe = async (payload: SubscribeStartPayload) => {
    if (!userId) {
      throw new Error('User not found.');
    }
    try {
      const response: AxiosResponse = await SubscriptionService.startSubscription({
        userId,
        payload: {
          ...payload,
          userId,
        },
      });

      const { sessionId } = response.data;

      if (!stripe) return;

      stripe
        .redirectToCheckout({
          sessionId: sessionId,
          // clientReferenceId: userId
        })
        .then((result: any) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
      // if (error) {
      // }
    } catch (err) {
      logger.logError(err);
    }
  };

  const unsubscribe = async (type: string) => {
    if (!userId) {
      throw new Error('User not found.');
    }
    const response: AxiosResponse = await SubscriptionService.cancelSubscription({ userId });
    console.log(response);

    // stripe.redirectToCheckout({
    //   sessionId: '{{CHECKOUT_SESSION_ID}}',
    // }).then((result: any) => {
    //   // If `redirectToCheckout` fails due to a browser or network
    //   // error, display the localized error message to your customer
    //   // using `result.error.message`.
    // });
  };

  const updateSubscription = async (payload: SubscriptionUpdateStartPayload) => {
    if (!userId) {
      throw new Error('User not found.');
    }
    const response: AxiosResponse = await SubscriptionService.updateSubscription({ userId, payload });
    console.log(response);

    // stripe.redirectToCheckout({
    //   sessionId: '{{CHECKOUT_SESSION_ID}}',
    // }).then((result: any) => {
    //   // If `redirectToCheckout` fails due to a browser or network
    //   // error, display the localized error message to your customer
    //   // using `result.error.message`.
    // });
  };

  return {
    subscribe,
    unsubscribe,
    updateSubscription,
  };
};

export default useSubscription;
