import React, { useCallback, useRef, CSSProperties } from 'react';
import IconSVG, { IconName } from './IconSVG';

import './Icon.css';

export interface IconColors {
  strokeColor?: string;
  fillColor?: string;
}

export enum IconPreset {
  hover_border_black = 'hover-border-black',
  hover_circle = 'hover-circle',
  hover_fill = 'hover-fill',
  bordered = 'bordered',
}

export enum IconSize {
  'small' = 'small',
  'large' = 'large',
}

interface Props {
  iconName: IconName;
  preset?: IconPreset;
  iconColors?: IconColors;
  filled?: boolean;
  size?: IconSize;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  className?: string;
  forwardRef?: Function;
  draggable?: boolean;
}

const Icon = (props: Props) => {
  const iconRef = useRef<HTMLDivElement | null>(null);
  const { className, forwardRef, iconName, preset, size, onClick, iconColors: propsIconColors, active, draggable = false, filled } = props;

  const getIconColors = useCallback(() => {
    const defaultColors = {
      fillColor: propsIconColors?.fillColor,
      strokeColor: propsIconColors?.strokeColor,
    };

    return defaultColors;
  }, [propsIconColors]);

  const getRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        iconRef.current = node;
        if (forwardRef) {
          forwardRef(node);
        }
      }
    },
    [forwardRef]
  );

  const sizeClass = size ? `szen-icon__${size}` : null;
  const baseVariant = filled ? 'icon-filled' : 'icon-outline';
  const presetClass = preset ? `${preset} icon__bordered` : null;
  const activeClass = active ? `${preset ? `${preset}` : 'icon'}__active` : null;

  return (
    <span
      ref={getRef}
      className={`szen-icon ${baseVariant} ${sizeClass} ${presetClass ?? ''} ${activeClass ?? ''} ${className || ''}`}
      onClick={onClick}
    >
      <IconSVG iconName={iconName} svgProps={{}} svgColors={getIconColors()} />
    </span>
  );
};

export default Icon;
