import BouncingCircles from './BouncingCircles';
import './LoadingSpinner.css';

interface BallIndicator {
  kind: 'balls';
  ballsize?: number;
}

interface CircleIndicator {
  kind: 'circle';
  r?: number;
}

type Props = CircleIndicator | BallIndicator;

const LoadingIndicator = (props: Props) => {
  switch (props.kind) {
    case 'balls':
      return <BouncingCircles ballsize={props.ballsize} />;
    case 'circle':
      return <div style={{ width: `${props.r || 30}px`, height: `${props.r || 30}px` }} className={'spinner-two'}></div>;
    default:
      return <BouncingCircles />;
  }
};

export default LoadingIndicator;
