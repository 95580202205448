import styled, { CSSProperties } from 'styled-components';
import { boolean } from 'yup';
import { getSpaceCSSProperty, LayoutHandlers, Sx, transformSx } from './LayoutItem';

export const StyledLayout = styled.div<{ childHorizontalSpacing: string | undefined } & CSSProperties>`
  display: flex;
  flex: ${(props) => props.flex};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};

  & > * {
    margin: ${(props) => props.childHorizontalSpacing};
  }

  & > .szen-fill-horizontal {
    flex-grow: 1;
  }

  & > .szen-fill-vertical {
    align-items: stretch;
  }
`;

export const getLayoutClassName = ({
  fillHorizontal,
  fillVertical,
  className,
}: {
  fillHorizontal?: boolean;
  fillVertical?: boolean;
  className?: string;
}) => `${className || ''}${fillHorizontal ? ' szen-fill-horizontal' : ''}${fillVertical ? ' szen-fill-vertical' : ''}`;

interface Props extends LayoutHandlers {
  children: React.ReactNode;
  horizontalAlign?: React.CSSProperties['justifyContent'];
  verticalAlign?: React.CSSProperties['alignItems'];
  height?: 'unset' | 'fill_parent';
  className?: string;
  style?: React.CSSProperties;
  sx?: Sx;
  wrap?: boolean;
  childHorizontalSpacing?: Sx['margin'];
  fillHorizontal?: boolean;
  fillVertical?: boolean;
}

const Layout = (props: Props) => {
  const { wrap, childHorizontalSpacing, className, fillHorizontal, fillVertical } = props;
  const style: CSSProperties = {
    ...transformSx(props.sx),
    ...props.style,
    height: props.height === 'fill_parent' ? '100%' : undefined,
  };
  const childStyle = getSpaceCSSProperty(childHorizontalSpacing);

  return (
    <StyledLayout
      onClick={props.onClick}
      style={{ ...style, flexWrap: wrap ? 'wrap' : undefined }}
      className={getLayoutClassName({ className, fillHorizontal, fillVertical })}
      justifyContent={props.horizontalAlign ?? 'flex-start'}
      alignItems={props.verticalAlign ?? 'stretch'}
      flex={props.fillHorizontal ? 1 : 0}
      childHorizontalSpacing={childStyle}
    >
      {props.children}
    </StyledLayout>
  );
};

export default Layout;
