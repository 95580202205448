import styled, { css } from 'styled-components';

export const media = {
  forPhoneOnly: (args: TemplateStringsArray) => css`
    @media (max-width: 599px) {
      ${css(args)}
    }
  `,
  forTabletPortraitUp: (args: TemplateStringsArray) => css`
    @media (min-width: 600px) {
      ${css(args)}
    }
  `,
  forTabletLandscapeUp: (args: TemplateStringsArray) => css`
    @media (min-width: 900px) {
      ${css(args)}
    }
  `,
  forDesktopUp: (args: TemplateStringsArray) => css`
    @media (min-width: 1200px) {
      ${css(args)}
    }
  `,
  forBigDesktopUp: (args: TemplateStringsArray) => css`
    @media (min-width: 1800px) {
      ${css(args)}
    }
  `,
};

export const OnlyPhone = styled.div`
  display: none;
  ${media.forPhoneOnly`
    display: block;
  `}
`;
export const AllButPhone = styled.div`
  display: block;
  ${media.forPhoneOnly`
    display: none;
  `}
`;
