import { useState } from 'react';
import Button from '../../Button/Button';
import { Formik, Field, Form, ErrorMessage, FormikErrors } from 'formik';
import { StyledFormRow } from '../../../../styles';

interface MyFormValues {
  email: string;
}

const SignUpForBetaForm = () => {
  const [submitDone, setSubmitDone] = useState(false);

  const baseURL = process.env.REACT_APP_baseURL;

  function navigateToHome() {
    window.location.href = `${process.env.REACT_APP_SITE_URL}`;
  }

  function submitEmail(values: MyFormValues) {
    const payload = {
      email: values.email,
    };
    fetch(`${baseURL}/betasignup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(payload),
    })
      .then(async (res) => {
        // const r = await res.json();
        setSubmitDone(true);
      })
      .catch((err) => {});
  }

  if (submitDone)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <div style={{ padding: '10px 20px 40px 20px' }}>Thank you! Once the beta opens, we'll send you email right away.</div>
        <Button buttonType={'site'} clicked={navigateToHome}>
          Return to home
        </Button>
      </div>
    );

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>Register your email to get notified when the beta opens.</div>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={submitEmail}
        validate={(values: MyFormValues) => {
          let errors: FormikErrors<MyFormValues> = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
      >
        {({ errors, status, touched, isSubmitting }) => (
          <Form translate={undefined} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StyledFormRow>
              <label htmlFor="email">
                <Field
                  autoFocus
                  className={'site--form-text-field'}
                  data-testid="email"
                  placeholder={'Email'}
                  type={'email'}
                  name={'email'}
                />
              </label>
              <ErrorMessage className={'form--error-message'} name={'email'} component={'div'} />
            </StyledFormRow>
            {status && status.message && <div>{status.message}</div>}
            <div data-testid="buttonholder" style={{ marginTop: '20px' }}>
              <Button type="submit" disabled={isSubmitting} buttonType={'site'}>
                Send
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForBetaForm;
