import { ILoginRes, IResetReq, ISetNewPasswordReq } from '@sandboxsurfer/szen-messages';
import Api from './Api';

interface RegisterCredentials {
  email: string;
  password: string;
}

interface LoginCredentials {
  email: string;
  password: string;
}

const service = {
  register(credentials: RegisterCredentials) {
    return Api().post<ILoginRes>('/auth/register', credentials);
  },
  login(credentials: LoginCredentials) {
    return Api().post<ILoginRes>('/auth/login', credentials);
  },
  stepin() {
    return Api().post<ILoginRes>('/auth/stepin');
  },
  reset(payload: IResetReq) {
    return Api().post('/auth/reset', payload);
  },
  setNewPassword(payload: ISetNewPasswordReq) {
    return Api().post('setnewpassword', payload);
  },
};

export default service;
