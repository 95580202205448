interface Base {
  id: LandingId;
  title: string;
  subtitle: string;
  // landingReference : string;
  text: string;
  backgroundColor: 'light' | 'dark';
}

interface LandingDataItemImage extends Base {
  images: string[];
  newImages: string[];
  imageAlt: string;
  textOn: 'right' | 'left';
}

interface LandingDataItemComponent extends Base {
  componentId: string[];
}

export type LandingId =
  | 'enrich'
  | 'tracks'
  | 'timeviews'
  | 'mapviews'
  | 'sharing'
  | 'characters'
  | 'research'
  | 'plotting'
  | 'analysis (stats)';

export function isLandingDataItemComponent(arg: any): arg is LandingDataItemComponent {
  return !!arg && !!arg.componentId;
}

type LandingDataItem = LandingDataItemImage | LandingDataItemComponent;

export const landingData: LandingDataItem[] = [
  {
    id: 'enrich',
    title: 'Your words in their context', // 'Text in context', // ''Text and connections.', // 'Enrich your writing with information and insights',
    subtitle: 'Embrace the complexities.', // Enjoy and embrace...
    images: ['enrich.svg'],
    newImages: ['enrich.svg'],
    imageAlt: 'yabudii',
    text: `Enrich your writing with information and insights. Understand the relations, discover and resolve problems. Follow dialogue. See the origins of information.`,
    textOn: `left`,
    backgroundColor: 'dark',
  },
  {
    id: 'tracks',
    title: 'Follow viewpoints or tracks that run through the story', // 'Manage the tracks that cut through your story / Organize tracks.
    subtitle: '---',
    images: ['tracks.svg'],
    newImages: ['tracks.svg'],
    imageAlt: 'yabudii',
    text: `
    Scenes of your story form sequences that your readers enjoy. 
    Use tracks to magane sequences of events that happen.
    Tracks help you organize how events and scenes follow each other.
    `,
    textOn: `right`,
    backgroundColor: 'light',
  },
  {
    id: 'timeviews',
    title: 'Timelines control flow of time', //'Control the flow of time',
    subtitle: '---',
    images: ['timeliner.svg'],
    newImages: ['timeliner2.png'],
    imageAlt: 'yabudii',
    text: 'Stories happen in time. With Storyvera you can control flow of time. Manage the timing of your scenes and flow of the events using timeliner.', //With Storyvera is built from groud up to manage time.
    textOn: `left`,
    backgroundColor: 'dark',
  },
  {
    id: 'mapviews',
    title: 'Maps show where it all happens.',
    subtitle: '---',
    images: ['mapper.svg'],
    newImages: ['mapper.svg'],
    imageAlt: 'yabudii',
    text: `Places of wonder, adventure, romance and dark betrayals. Stories happen in space and time. Maps relate characters and event
    to places and show where things happen.`,
    textOn: `right`,
    backgroundColor: 'light',
  },
  {
    id: 'sharing',
    title: 'Collections organize context', // 'Share your characters, locations and context events between your projects.', Organize and share the context.
    subtitle: '---',
    images: ['share.svg'],
    newImages: ['share.svg'],
    imageAlt: 'yabudii',
    text: `Put everything your story need to grow into a backpack and hand it over to it.`,
    textOn: `left`,
    backgroundColor: 'dark',
  },
  // {
  //   id: 'characters',
  //   title: 'Feel for your characters.',
  //   subtitle: '---',
  //   images: ['characters.svg'],
  //   newImages: ['characters.svg'],
  //   imageAlt: 'yabudii',
  //   text: `Love your characters or hate them but know them well in either case.
  //   Your characters go through a lot. And so do you as you craft their journey.
  //   Storyvera helps you keep your characters accountable for their actions.`,
  //   textOn: `right`,
  //   backgroundColor: 'light',
  // },
  {
    id: 'research',
    title: 'Build your story on facts and data.',
    subtitle: '---',
    images: ['research.svg'],
    newImages: ['research.svg'],
    imageAlt: 'yabudii',
    text: `Books, web sites and videos. Interviews you have made. Organize and rule all this information.`,
    textOn: `left`,
    backgroundColor: 'dark',
  },
  {
    id: 'plotting',
    title: 'Plot driven story development',
    subtitle: '---',
    images: ['plotter.svg'],
    newImages: ['plotter.svg'],
    imageAlt: 'Planning view',
    text: `Planning views offers birds eye view to the story.
    Construct plots out of sequences of events that you place in scene`,
    textOn: `right`,
    backgroundColor: 'light',
  },
  {
    id: 'analysis (stats)',
    title: 'Analyze structure and progress',
    subtitle: '---',
    images: ['stats.svg'],
    newImages: ['stats.svg'],
    imageAlt: 'yabudii',
    text: `Understand where you are with your project. Comprehensive statistics help you analyze the structure of the story from multiple vantage points.`,
    textOn: `left`,
    backgroundColor: 'dark',
  },
];
