import Logo from '../../../components/Logo/Logo';
import NavigationItems from './NavigationItems/NavigationItems';
import ContentItems from './ContentItems';
// import LanguageSelection from './LanguageSelection/LanguageSelection'
import Burger from '../../../components/Burger';

import styled from 'styled-components';
import { media } from '../../../styles';

// import { history } from '../../../RootApp'

const StyledMainHeader = styled.header`
  background-color: white;
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  z-index: 90;
  border-bottom: 1px solid #dddddd;
`;

const StyledWidthConstrainer = styled.div`
  width: 100%;
  max-width: 1400px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  ${media.forTabletPortraitUp`
    grid-template-columns: 0.2fr 1fr 1fr;

  `}

  ${media.forDesktopUp`
    grid-template-columns: 1fr 1fr 1fr;

  `}
`;

const StyledLeft = styled.nav`
  display: flex;
  align-items: flex-start;
`;
const StyledMiddle = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.forPhoneOnly`
    display: none;
  `}
`;
const StyledRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.forPhoneOnly`
    display: none;
  `}
`;

const StyledMobileRight = styled.div`
  display: none;

  ${media.forPhoneOnly`
    display: block;
    justify-self: end;
  `}
`;

interface Props {
  toggleBurger: Function;
  isOpenBurger: boolean;
  onHeaderItemClicked: Function;
}
const SiteHeader = (props: Props) => {
  return (
    <StyledMainHeader>
      <StyledWidthConstrainer>
        <StyledLeft>
          <Logo toggleBurger={props.toggleBurger} isOpenBurger={props.isOpenBurger} />
        </StyledLeft>
        <StyledMiddle>
          <ContentItems onHeaderItemClicked={props.onHeaderItemClicked} />
        </StyledMiddle>
        <StyledRight>
          <NavigationItems insideBurger={false} />
        </StyledRight>
        <StyledMobileRight>
          <Burger toggleBurger={props.toggleBurger} isOpenBurger={props.isOpenBurger} />
        </StyledMobileRight>
      </StyledWidthConstrainer>
    </StyledMainHeader>
  );
};

export default SiteHeader;

/* <StyledMainNav>  */
/* </StyledMainNav> */

/* <StyledWidthContainer> */
/* </StyledWidthContainer> */

/* <LanguageSelection 
  changeToLocale={props.changeToLocale}
  locale={props.locale}
/> */

// export const StyledWidthContainer = styled.div`
//   width: 100%;
//   max-width: 1200px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `

// const StyledMainNav = styled.nav`
//   display: flex;
//   align-items: center;
// `
