import { useEffect, useState } from 'react';
import ContentService from '../../../services/ContentService';
import { CategoryType, TopicType } from '../../../types';
import TwoLevelPage from '../templates/TwoLevelPage';

// import { history } from '../../../RootApp';

interface Props {
  scrollTo: (p: { pos: number; noScroll?: true }) => void;
}

const FeaturesPage = (props: Props) => {
  const [topics, setTopics] = useState<null | TopicType[]>(null);

  useEffect(() => {
    getFeatureTopics();

    async function getFeatureTopics() {
      const response = await ContentService.getContent('features');
      const data = (await response.json()) as CategoryType;
      setTopics(data.topics);
    }
  }, []);

  if (!topics) return null; // <div>Loading</div>;

  return !topics ? null : <TwoLevelPage initialActiveArticleId={''} scrollTo={props.scrollTo} title={'Features'} topics={topics} />;
};

export default FeaturesPage;
