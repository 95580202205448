import { nanoid } from 'nanoid';

export const svLocalStorage = {
  getToken: () => {
    return localStorage.getItem('token');
  },
  clearToken: () => {
    return localStorage.removeItem('token');
  },
  getRCID: () => {
    const rcid = localStorage.getItem('rcid');
    if (rcid) {
      return rcid;
    } else {
      const newRCID = nanoid();
      localStorage.setItem('rcid', newRCID);
      return newRCID;
    }
  },
};
