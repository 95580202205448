import styled from 'styled-components';

const StyledPageContainer = styled.div`
  padding-top: 20px;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPage = styled.div`
  display: flex;
  max-width: 1000px;
  justify-content: center;
  width: 100%;
`;
const StyledPageTitle = styled.div`
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const StyledMain = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 80%;
`;

const StyledContent = styled.div``;

interface Props {
  title: string;
  children: React.ReactChild | React.ReactChild[];
}

const FixedHeightPage = (props: Props) => {
  const { title, children } = props;
  return (
    <StyledPageContainer>
      <StyledPage>
        <StyledMain>
          <StyledPageTitle>{title}</StyledPageTitle>
          <StyledContent>{children}</StyledContent>
        </StyledMain>
      </StyledPage>
    </StyledPageContainer>
  );
};

export default FixedHeightPage;
