import React, { useState, useEffect } from 'react';
import ContentService from '../../../services/ContentService';
import { TopicType } from '../../../types';
import TopicPage from '../templates/TopicPage';
import SimplePage from '../templates/SimplePage';

const ReleasesPage = () => {
  const [topic, setTopic] = useState<null | TopicType>(null);

  useEffect(() => {
    getArticle();
    async function getArticle() {
      const response = await ContentService.getContent('updates');
      const data = (await response.json()) as TopicType;
      setTopic(data);
    }
  }, []);

  return <SimplePage title={`Releases`}>{!topic ? null : <TopicPage topic={topic} />}</SimplePage>;
};

export default ReleasesPage;
