import styled from 'styled-components';
import { media } from '../../../styles';

const StyledHolder = styled.div`
  /* height: calc(100vh - 56px); */
  width: 100%;
  flex: 1 1 auto;
  background-image: url('img/beta.svg');
  background-size: cover;
  background-position: center;
  /* background-size: cover; */
  /* background-size: 100%; */
  display: flex;
  justify-content: flex-start;
  /* padding-right: 30px; */
  position: relative;

  ${media.forPhoneOnly`
    background-image: none;
  `}
`;

const StyledMobileImage = styled.img`
  display: none;

  ${media.forPhoneOnly`
    display: inline-block;
    margin-bottom: 10px;
    height: 200px;

  `}
`;

const Beta = () => {
  return (
    <StyledHolder>
      {/* <FixedHeightPage title={'Beta'}> */}
      {/* <img className={'beta-background'} src="img/beta.svg" alt="Storyvera landing as a rocket" /> */}
      <div className={'site-beta-text'}>
        <div>
          <div className={'site-beta-title'}>It's a touchdown!</div>
          <StyledMobileImage src="img/touchdown.svg" />
        </div>
        <div className={'site-paragraph'}>
          Hello planet Earth, Storyvera has landed. So here we are! It was a sweet landing, not a crash landing at all. Oh, no, no, no.
          Despite our great initial success, it seems that not all systems are fully operational yet. And there are some scratches on the
          paint and some unexpected dents.
        </div>
        <div className={'site-paragraph'}>
          Currently Storyvera is in beta phase and things can be a bit unorganized here and there. If you don’t mind this, you can SOON
          start using Storyvera. Things are tidied up one thing at a time. Early in the beta, Storyvera receives an update on weekly basis
          that fixes problems, fine tunes behavior and introduces new features. Later, the cycle of updates will be scaled back to monthly
          basis to stabilize things a bit.
        </div>
        <div className={'site-paragraph'}>During beta you can use Storyvera for FREE.</div>
      </div>
      {/* </FixedHeightPage> */}
    </StyledHolder>
  );
};

export default Beta;
