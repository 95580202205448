import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { StyledLayout } from './Layout';
import { StyledStack } from './Stack';

const StyledLayoutItem = styled.div<CSSProperties>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};

  ${StyledLayout} && {
    justify-self: ${(props) => props.justifySelf};
  }

  ${StyledStack} && {
    align-self: ${(props) => props.justifySelf};
  }
`;

export interface Sx {
  padding?: SpacingOption;
  margin?: SpacingOption;
}

export interface LayoutHandlers {
  onClick?: (e: React.MouseEvent) => void;
}

interface Props extends LayoutHandlers {
  grow?: boolean;
  style?: React.CSSProperties;
  size?: number;
  sx?: Sx;
  children: React.ReactNode;
  horizontalAlign?: React.CSSProperties['justifyContent'];
  horizontalSelfAlign?: React.CSSProperties['justifyContent'];
  verticalAlign?: React.CSSProperties['alignItems'];
  className?: string;
}

type SpacingSize = 'small' | 'medium' | 'large';
type SpacingDirection = 'around' | 'horizontal' | 'vertical' | 'vertical-bottom';
export type SpacingOption = `${SpacingDirection}-${SpacingSize}` | 'none';

export const getSpaceCSSProperty = (spacing: SpacingOption | undefined): string | undefined => {
  const paddings: Record<SpacingOption, string | undefined> = {
    'around-small': 'var(--space-small) var(--space-small)',
    'around-medium': 'var(--space-medium) var(--space-medium)',
    'around-large': 'var(--space-large) var(--space-large)',
    'horizontal-small': '0 var(--space-small)',
    'horizontal-medium': '0 var(--space-medium)',
    'horizontal-large': '0 var(--space-large)',
    'vertical-small': 'var(--space-small) 0',
    'vertical-medium': 'var(--space-medium) 0',
    'vertical-large': 'var(--space-large) 0',
    'vertical-bottom-small': '0 0 var(--space-small) 0',
    'vertical-bottom-medium': '0 0 var(--space-medium) 0',
    'vertical-bottom-large': '0 0 var(--space-large) 0',
    none: undefined,
  };
  return spacing ? paddings[spacing] : undefined;
};

export const transformSx = (sx: Sx | undefined): CSSProperties => {
  if (!sx) {
    return {};
  }
  return {
    padding: sx.padding ? getSpaceCSSProperty(sx.padding) : undefined,
    margin: sx.margin ? getSpaceCSSProperty(sx.margin) : undefined,
  };
};

/**
 * NOTE: This is simple experiment. Not implemented as a full blown layout system.
 */
const LayoutItem = (props: Props) => {
  const style: CSSProperties = {
    width: props.size ? `${(Math.max(1, Math.min(Math.floor(props.size), 12)) * 100) / 12}%` : undefined,
    ...transformSx(props.sx),
  };

  // const className = `layout-item ${props.className || ''} `

  return (
    <StyledLayoutItem
      onClick={props.onClick}
      className={props.className}
      style={{ ...props.style, ...style, flex: props.grow ? '1' : undefined }}
      alignItems={props.verticalAlign ?? 'stretch'}
      justifyContent={props.horizontalAlign ?? 'flex-start'}
      justifySelf={props.horizontalSelfAlign}
    >
      {props.children}
    </StyledLayoutItem>
  );
};

export default LayoutItem;
