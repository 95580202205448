import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TopicType } from '../../../types';
import { media } from '../../../styles';
import { serialize } from '../../../slate/serializer';

const StyledContent = styled.div`
  margin-bottom: 800px;
  ${media.forPhoneOnly`
  padding: 0 20px;
`}
`;

const StyledArticleTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  text-transform: uppercase;
  border-bottom: 1px solid black;
`;

const StyledArticle = styled.div`
  margin: 10px 0 40px 0;
`;

const StyledArticles = styled.div``;

interface Props {
  topic: TopicType;
  setArticleRefArray?: Function;
}

const TopicPage = (props: Props) => {
  const { topic, setArticleRefArray } = props;
  const [refArray, setRefArray] = useState<any>([]);

  useEffect(() => {
    if (setArticleRefArray) {
      const newArray = topic.articles.map((article) => React.createRef());
      setRefArray(newArray);
      setArticleRefArray(newArray);
    }

    return () => {
      if (setArticleRefArray) {
        setArticleRefArray(null);
      }
    };
  }, [topic, setArticleRefArray]);

  const d_articles =
    props.topic &&
    props.topic.articles &&
    props.topic.articles.map((article, index) => {
      return (
        <div key={article.id} ref={refArray[index]}>
          <StyledArticle key={article.id}>
            {/* <StyledArticleTitle>{article.title}</StyledArticleTitle> */}
            {article.slate.map((node, i) => serialize(node, i))}
            {!article.imageSrc ? null : <img src={article.imageSrc} alt="road" />}
          </StyledArticle>
        </div>
      );
    });

  return (
    <StyledContent>
      <StyledArticles>{d_articles}</StyledArticles>
    </StyledContent>
  );
};

export default TopicPage;
