import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import Twitter from '../../../assets/Twitter_Social_Icon_Rounded_Square_Color.png';
import Facebook from '../../../assets/flogo_RGB_HEX-72.png';
import { media } from '../../../styles';
import Layout from '../../../components/UI/Layout';

const StyledFooter = styled.div`
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 20px;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  height: var(--site-footer-height, 216px);

  ${media.forPhoneOnly`
    height: auto;
  `}
`;

const StyledFooterRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const StyledFooterColumn = styled.div`
  margin: 10px 10px;
  width: 200px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SocialBar = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;

  img {
    height: 24px;
    margin: 0 10px;
  }
`;

const StyledFooterItems = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;

  ${media.forPhoneOnly`
    width: 100%;
    align-items: center;
  `}
`;
const StyledFooterItem = styled.li`
  * {
    margin: 3px 0;
    color: black;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    display: block;
    &:hover {
      color: gray;
    }
  }
`;

interface FooterItemNavLink {
  title: string;
  pathname: string;
}

interface FooterItemOther {
  title: string;
}

type FooterItem = FooterItemNavLink | FooterItemOther;

const leftItems: FooterItem[] = [
  {
    title: 'Term of Service',
    pathname: '/terms',
  },
  {
    title: 'Privacy',
    pathname: '/privacy',
  },
  {
    title: 'Cookie Policy',
    pathname: '/cookiepolicy',
  },
];

const centerItems: FooterItem[] = [
  {
    title: 'Beta',
    pathname: '/beta',
  },
  {
    title: 'Releases',
    pathname: '/releases',
  },
];

function isFooterItemNavLink(arg: any): arg is FooterItemNavLink {
  return !!arg && !!arg.pathname;
}

const Footer = () => {
  function getLinks(items: FooterItem[]) {
    return (
      <StyledFooterItems>
        {items.map((item) => {
          if (isFooterItemNavLink(item)) {
            return (
              <StyledFooterItem key={item.title}>
                <NavLink to={{ pathname: item.pathname }}>{item.title}</NavLink>
              </StyledFooterItem>
            );
          }
          return <div>{item.title}</div>;
        })}
      </StyledFooterItems>
    );
  }

  function mailToClicked() {
    const piece1 = `support`;
    const piece2 = `storyvera`;
    window.location.href = `mailto:${piece1}@${piece2}.com`;
  }

  return (
    <StyledFooter>
      <StyledFooterRow>
        <StyledFooterColumn>
          {/* <StyledColumnTitle>About</StyledColumnTitle> */}
          {getLinks(leftItems)}
        </StyledFooterColumn>
        <StyledFooterColumn>
          {/* <StyledColumnTitle>Support</StyledColumnTitle> */}
          <StyledFooterItems>{getLinks(centerItems)}</StyledFooterItems>
        </StyledFooterColumn>
        <StyledFooterColumn>
          {/* <StyledColumnTitle>Zoo</StyledColumnTitle> */}
          <StyledFooterItems>
            <StyledFooterItem>
              <div onClick={mailToClicked}>Contact</div>
            </StyledFooterItem>
          </StyledFooterItems>
        </StyledFooterColumn>
      </StyledFooterRow>
      <SocialBar>
        <a href="https://twitter.com/storyvera1" target="_blank" rel="noopener noreferrer">
          <img src={Twitter} alt="Twitter logo" />
        </a>
        <a href="https://www.facebook.com/Storyvera-102060804816278" target="_blank" rel="noopener noreferrer">
          <img src={Facebook} alt="Facebook logo" />
        </a>
      </SocialBar>
      <Layout sx={{ margin: 'vertical-medium' }} horizontalAlign={'center'}>
        (C) Aegna Oy 2022. All rights reserved.
      </Layout>
    </StyledFooter>
  );
};

export default Footer;
