import styled from 'styled-components';
import { media } from '../../../styles';
import Icon from '../../../icons/Icon';
import { IconName } from '../../../icons/IconSVG';

const StyledHeaderItems = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: auto;

  ${media.forPhoneOnly`
    margin: 20px 0;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    `}
`;

const StyledHeaderItem = styled.li<{ isActive: boolean }>`
  font-family: var(--baseFont);
  color: black;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-weight: ${(props) => (props.isActive ? 'bold' : undefined)};
  padding: 0 25px 0 25px;
  height: 100%;
  display: block;
  &:hover {
    color: gray;
  }

  ${media.forPhoneOnly`
    font-size: 24px;
    margin: 5px 0;
    padding: 0 0 0px 0;
    height: auto;
    width: 100%;
    /* border-bottom: 1px solid gray; */
    `}
`;

const StyledOnlyMobileIcon = styled.div`
  display: none;

  ${media.forPhoneOnly`
    margin-right: 20px;
    display: inline-block;
  `}
`;

interface Props {
  onHeaderItemClicked: Function;
}

const links: { title: string; pathname: string; iconName: IconName }[] = [
  {
    title: 'Features',
    pathname: '/features',
    iconName: 'features',
  },
  {
    title: 'Pricing',
    pathname: '/pricing',
    iconName: 'pricing',
  },
  // {
  //   title: 'Releases',
  //   pathname: '/releases',
  //   iconName: 'releases',
  // },
  // {
  //   title: 'Beta',
  //   pathname: '/beta',
  //   iconName: 'beta',
  // },
];

const ContentItems = (props: Props) => {
  function navigate(pathname: string) {
    // const
    // const pathname = links.find((item) => item.title === title).pathname : '/';
    props.onHeaderItemClicked(pathname);
  }

  return (
    <StyledHeaderItems>
      {links.map((item) => {
        return (
          <StyledHeaderItem
            key={item.title}
            isActive={false} //{item.pathname === history.location.pathname}
            onClick={(e: React.MouseEvent) => navigate(item.pathname)}
          >
            <StyledOnlyMobileIcon>
              <Icon iconName={item.iconName} iconColors={{ fillColor: 'black' }} />
            </StyledOnlyMobileIcon>
            {item.title}
          </StyledHeaderItem>
        );
      })}
    </StyledHeaderItems>
  );
};

export default ContentItems;
