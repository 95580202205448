import axios, { AxiosResponse } from 'axios';
import logger from '../services/LoggerService';
import AuthenticationService from '../services/AuthenticationService';
import { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { svLocalStorage } from '../localstorage';
import { useNavigate } from 'react-router-dom';

export interface LoginPayload {
  credentials: {
    email: string;
    password: string;
  };
}

export interface SignUpStartPayload {
  data: {
    email: string;
    password: string;
    betacode: string;
  };
}

export interface SubscribeStartPayload {
  planType: 'monthly' | 'yearly';
}

export interface SubscriptionUpdateStartPayload {
  type: 'reactivate' | 'deactivate';
}

const useAuth = () => {
  const auth = useContext(AuthContext);

  const signUpUser = async (payload: SignUpStartPayload): Promise<{ success: boolean; msg?: any }> => {
    const { data } = payload;
    try {
      const response: AxiosResponse = await AuthenticationService.register(data);
      auth.setAuthData(response.data);
      return { success: true };
    } catch (err) {
      const msg = axios.isAxiosError(err) && err.response && 'error' in err.response.data ? err.response.data.error : undefined;
      logger.logError(err);
      return { success: false, msg };
    }
  };

  const loginUser = async (payload: LoginPayload): Promise<{ success: boolean; msg?: any }> => {
    const { credentials } = payload;
    if (!credentials || !credentials.email || !credentials.password) throw new Error('Login info error.');

    try {
      const response = await AuthenticationService.login(credentials);
      auth.setAuthData(response.data);
      return { success: true };
    } catch (err) {
      const msg = axios.isAxiosError(err) && err.response && 'error' in err.response.data ? err.response.data.error : undefined;
      logger.logError(err);
      return { success: false, msg };
    }
  };

  const logoutUser = () => {
    auth.setAuthData(null);
    console.log('Should direct to somewhere else?');
  };

  return {
    auth,
    signUpUser,
    loginUser,
    logoutUser,
  };
};

export default useAuth;
