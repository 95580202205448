import styled from 'styled-components';
import { media } from '../../../styles';
import { useNavigate } from 'react-router-dom';

const StyledLegalNav = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  display: flex;
  position: sticky;
  justify-content: center;
  top: 0px;
  background: white;
  width: 100%;
  height: 40px;

  > * {
    margin-bottom: 5px;
  }

  ${media.forTabletPortraitUp`
    padding-top: 0;
    width: 180px;
    position: sticky;
    display: inline-block;
    top: 160px;
    margin-left: 20px;
  `}
`;

const StyledLegalNavItem = styled.div`
  font-size: 18px;
  cursor: pointer;

  ${media.forPhoneOnly`
  font-size: 14px;
  margin: 0 5px;
  `}
`;

const pages = [
  {
    title: 'Terms of service',
    pathname: '/terms',
  },
  {
    title: 'Privacy',
    pathname: '/privacy',
  },
  {
    title: 'Cookie policy',
    pathname: '/cookiepolicy',
  },
];

const LegalNav = () => {
  const navigate = useNavigate();

  function navigateToPage(pathname: string) {
    navigate(pathname);
  }

  return (
    <StyledLegalNav>
      {pages.map((page) => {
        const isActive = true; // history.location.pathname === page.pathname;
        return (
          <StyledLegalNavItem
            key={page.title}
            className={!isActive ? undefined : 'legalnav-item-active'}
            onClick={() => navigateToPage(page.pathname)}
          >
            {page.title}
          </StyledLegalNavItem>
        );
      })}
    </StyledLegalNav>
  );
};

export default LegalNav;
