import { useState } from 'react';
import Dialogue from '../../../components/Dialogue';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthenticationService from '../../../services/AuthenticationService';
import Button from '../../../components/UI/Button/Button';
import SimplePage from '../templates/SimplePage';

const NewPasswordPage = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [password, setPassword] = useState('');
  const [updateStatus, setUpdateStatus] = useState<'success' | 'fail' | undefined>(undefined);
  const token = query.get('token');
  const navigate = useNavigate();

  // TODO: This should go to useAuth hook
  async function startUpdate() {
    if (password && token) {
      const response = await AuthenticationService.setNewPassword({
        password: password,
        token: token,
      });
      if (response.status === 200) {
        setUpdateStatus('success');
        return;
      }
      setUpdateStatus('fail');
    }
  }

  function navigateToLogin() {
    navigate('/login');
  }

  //TODO: Add password input below
  const form = (
    <Dialogue title={'New Password'}>
      <div>
        {/* <Input
          data={{
            config: {
              elementType: 'input',
              elementConfig: {
                type: 'text',
              },
            },
            value: password,
            changed: (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
          }}
        /> */}
        <Button buttonType={'site'} clicked={startUpdate}>
          Update
        </Button>
      </div>
    </Dialogue>
  );

  const updateSuccess = (
    <Dialogue title={'Update succesful.'}>
      <div>
        <Button buttonType={'site'} clicked={navigateToLogin}>
          Log in
        </Button>
      </div>
    </Dialogue>
  );

  const updateFailure = (
    <Dialogue title={'Update failure.'}>
      <div>Update failure.</div>
    </Dialogue>
  );

  function getContent() {
    switch (updateStatus) {
      case undefined:
        return form;
      case 'success':
        return updateSuccess;
      case 'fail':
        return updateFailure;
      default:
        return <div>Please contact support@storyvera.com</div>;
    }
  }

  return <SimplePage title={''}>{getContent()}</SimplePage>;
};

export default NewPasswordPage;
