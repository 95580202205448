import styled from 'styled-components';
import { media } from './media';

export const StyledFormRow = styled.div`
  min-height: 64px;
`;

export const StyledFormRowFlex = styled.div`
  display: flex;
  min-height: 60px;
`;

export const StyledFormSubtitle = styled.div`
  margin-bottom: 16px;
`;

export const StyledFormBlock = styled.div`
  margin-bottom: 20px;
`;

export const StyledInputHolder = styled.div<React.CSSProperties>`
  display: flex;
  border: none;
  & > label {
    margin-bottom: ${(props) => (props.flexDirection === 'column' ? '4px' : undefined)};
  }

  ${media.forPhoneOnly`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

interface StyledLabelProps {}
export const StyledLabel = styled.label<StyledLabelProps>`
  padding-right: 5px;
  font-size: 12px;
  overflow-x: hidden;
  white-space: nowrap;
  color: var(--panelTextColor);
  user-select: none;
`;
