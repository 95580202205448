import { createRoot } from 'react-dom/client';
import './index.css';
import './animations.css';
import './scrollbar.css';
import RootApp from './RootApp';
import AuthContextProvider from './AuthContext';

const domNode = document.getElementById('root');
const root = domNode ? createRoot(domNode) : null;

root?.render(
  <AuthContextProvider>
    <RootApp />
  </AuthContextProvider>
);
