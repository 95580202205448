import styled from 'styled-components';
import { media } from '../../../styles';

export const StyledPageContainer = styled.div`
  /* padding-top: 40px; */
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const StyledPage = styled.div`
  display: flex;
  max-width: 800px;
  justify-content: center;
  width: 100%;
`;
export const StyledPageTitle = styled.div`
  font-size: 52px;
  font-weight: bold;
  margin-top: 80px;
  margin-bottom: 20px;

  ${media.forPhoneOnly`
    font-size: 32px;
  `}
`;

export const StyledMain = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  ${media.forPhoneOnly`
    margin-top: 10px;
  `}/* flex: 1 1 80%; */
`;

export const StyledContent = styled.div`
  width: 100%;
  ${media.forPhoneOnly`
    padding: 10px 20px;
  `}
`;

interface Props {
  title: string;
  children: React.ReactChild | (React.ReactChild | null)[] | null;
}

const SimplePage = (props: Props) => {
  const { title, children } = props;
  return (
    <StyledPageContainer>
      <StyledPage>
        <StyledMain>
          <StyledPageTitle>{title}</StyledPageTitle>
          <StyledContent>{children}</StyledContent>
        </StyledMain>
      </StyledPage>
    </StyledPageContainer>
  );
};

export default SimplePage;
