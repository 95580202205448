import styled, { keyframes } from 'styled-components';

const StyledSpinnerWrap = styled.div`
  width: 100%;
  padding: 0px 0;
`;

const frames = keyframes`

  0% {
    opacity: 0;
    transform: translateY(0px);
  }

  25% {
    opacity: 1;
    transform: translateY(-10px);
  }

  50% {
    opacity: 1;
    transform: translateY(-10px);
  }

  100% {
    opacity: 0;
    transform: translateY(0px);
  }
`;

const StyledSpinner = styled.svg`
  display: block;
  margin: 0 auto;
  fill: #000;

  circle {
    animation: ${frames} 2s cubic-bezier(0.05, 0.2, 0.35, 1) infinite;

    &:nth-child(2) {
      animation-delay: 0.18s;
    }

    &:nth-child(2) {
      animation-delay: 0.36s;
    }
  }
`;

interface Props {
  ballsize?: number;
}

// https://kyleshevlin.com/loading-state-trick-for-stateless-functional-components-in-react
const BouncingCircles = (props: Props) => {
  const { ballsize } = props;
  return (
    <StyledSpinnerWrap>
      <StyledSpinner width="60px" height="20px" viewBox="0 0 60 20" xmlns="http://www.w3c.org/2000/svg">
        <circle cx="7" cy="15" r={`${ballsize || 4}`} />
        <circle cx="30" cy="15" r={`${ballsize || 4}`} />
        <circle cx="53" cy="15" r={`${ballsize || 4}`} />
      </StyledSpinner>
    </StyledSpinnerWrap>
  );
};

export default BouncingCircles;
