import React, { useState, useEffect } from 'react';
import ContentService from '../../../services/ContentService';
import { ArticleType } from '../../../types';
import { serialize } from '../../../slate/serializer';
import LegalPage from '../templates/LegalPage';

const TermsPage = () => {
  const [article, setArticle] = useState<null | ArticleType>(null);

  useEffect(() => {
    getArticle();
    async function getArticle() {
      const response = await ContentService.getContent('terms');
      const data = (await response.json()) as ArticleType;
      setArticle(data);
    }
  }, []);

  if (!article) return null;

  const content = article.slate.map((node, i) => serialize(node, i));
  // const parser = new DOMParser();
  // const doc = parser.parseFromString(content.join(''), 'text/html');

  return !article ? null : (
    <LegalPage title={'Terms of Service'}>
      {content}
      {/* {article.slate.map((node) => serialize(node))} */}
    </LegalPage>
  );
};

export default TermsPage;
