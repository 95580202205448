import FixedHeightPage from '../templates/FixedHeightPage';
import Dialogue from '../../../components/Dialogue';
import LoginForm from '../../../components/UI/Forms/AuthForms/LoginForm';
import { NavLink } from 'react-router-dom';
import Stack from '../../../components/UI/Stack';

const LoginPage = () => {
  return (
    <FixedHeightPage title={''}>
      <Dialogue title={'Sign In'}>
        <LoginForm />
        <Stack style={{ marginTop: '16px' }} childMargins="vertical-small">
          <NavLink to={{ pathname: '/reset' }}>Forgot your password?</NavLink>
          <NavLink to={{ pathname: '/signup' }}>New user? Create account</NavLink>
        </Stack>
      </Dialogue>
    </FixedHeightPage>
  );
};

export default LoginPage;
